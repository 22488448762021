import numeral from "numeral";
import React from "react";
import styles from "./CommonCellRenderers.module.scss";
import { InlineLoader } from "./../Loaders";
import classNames from "classnames";
import _ from "lodash";
import NotAvailableCellData from './NotAvailableCellData'

const CommonCellRenderers = {};

export default CommonCellRenderers;

CommonCellRenderers.CapacityRenderer = ({value}) => {
    return <span>
        {numeral(value).format("0,0.[00] ib")}
    </span>
}

CommonCellRenderers.CapacityRendererTiB = ({value}) => {
    return <span>
        {numeral(value / 1024 / 1024 / 1024 / 1024).format("0,0.[00]")}
    </span>
}

CommonCellRenderers.CapacityRendererGib = ({value}) => {
    return <span>
        {numeral(value / 1024 / 1024 / 1024).format("0,0.[00]")}
    </span>
}

CommonCellRenderers.StatusRenderer = (statusesMap) => ({value}) => {
    let status = statusesMap[value];
    if(_.isString(status)) {
        status = {
            classname: status,
            label: value,
            isLoadingStatus: status === "loading"
        }
    }
    return <div className={styles["status"]}>
        {status.isLoadingStatus ? <InlineLoader className={styles["loader"]}/> : <span className={classNames(styles["circle"], styles[status.classname])}/> }
        <span className={classNames(styles["text"], styles[status.classname])}>{status.label}</span>
    </div>
}
CommonCellRenderers.NotAvailableCellData = NotAvailableCellData
