import React, {useState} from "react";
import styles from "./Filters.module.scss";
import classNames from "classnames";
import {Checkbox} from "./../Forms";
import _ from "lodash";
import Select, { components } from "react-select";
import ButtonsRow from "./ButtonsRow";

const selectStyle = {
    menuList: base => ({ ...base,zIndex:2}),
    menu: base => ({ ...base,zIndex:2, borderRadius:0, padding:'10px'}),
    control: base => ({...base, border: "solid 1px #979797", minHeight: 40, borderRadius: 0, marginBottom: 28, marginTop: 7 }),
    indicatorSeparator: (base) => ({...base, display:'none'}),
    singleValue: (base) => ({...base, color:'#636363',fontSize:'13px'}),
    option: (base,{isFocused,isSelected}) => ({...base, color: isSelected ? '#fff' : '#636363', 'backgroundColor':isSelected?'#3d84ff':isFocused?'#f5f5f5':null}),
    valueContainer: (base) => ({...base, paddingLeft: 15}),
    menuPortal: (base) => ({...base, zIndex: 300000002})
};

const drowpDownOptionLabel = showValuesWithLabel => ({label, value}) => {
    const valueIsLabel = value === label;

    if(!showValuesWithLabel || valueIsLabel || (!valueIsLabel && !value)) {
        return <span>{label}</span>
    } else {
        return <span><span>{label}</span>|<span style={{fontSize: 13, fontStyle: "italic"}}>{value}</span></span>
    }
};

const Components = {
    Option: props => {
        return <components.Option {...props} innerProps={{...props.innerProps, onClick: (e) => {
                e.stopPropagation();
                props.innerProps.onClick(e);
            }}}/>
    },
    MultiValueRemove: props => {
        return <components.MultiValueRemove {...props} innerProps={{...props.innerProps, onClick: (e) => {
                e.stopPropagation();
                props.innerProps.onClick(e);
            }}}/>
    }
}

export const DropdownFilter = ({filterOptions, filterState, close, updateFilter, className, filterConfig}) => {
    const [internalState, setInternalState] = useState(filterState.valuesArray || []);

    return  <div className={classNames(styles['filter'], className)} style={{minWidth: 285}}>
        <div className={styles['dropdown-filter-content']}>
            {!filterOptions || filterOptions.length === 0 && <div>No options</div>}
            {filterOptions && filterOptions.length > 0 &&
            <div>
                <label className={styles['drop-down-label']}>{filterConfig.label}</label>
                <Select styles={selectStyle}
                        components={Components}
                        onChange={setInternalState}
                        options={filterOptions}
                        value={internalState}
                        isSearchable={ true }
                        isMulti={ true }
                        placeholder={filterConfig.placeholder}
                        formatOptionLabel={drowpDownOptionLabel(filterConfig.showValuesWithLabel)}
                        onValueChange
                />
            </div>}
        </div>
        <ButtonsRow>
            <button type="button" onClick={() => {
                setInternalState([]);
            }}>Clear</button>
            <button type="button" onClick={(e) => {
                updateFilter(_.pickBy(_.keyBy(internalState, "value")));
                close(e);
            }}>Apply</button>
        </ButtonsRow>
    </div>
};


export const CheckboxFilter = ({filterOptions, filterState, close, updateFilter, className}) => {
    const [internalState, setInternalState] = useState(filterState.values || {});
    return  <div className={classNames(styles['filter'], className)}>
        <div className={styles['checkbox-filter-content']}>
            {!filterOptions || filterOptions.length === 0 && <div>No options</div>}
            {filterOptions && filterOptions.length > 0 && filterOptions.map(({value, label,disabled}) => {
                return <Checkbox key={value} disabled={disabled} name={value} checked={internalState[value] || false} onChange={() => setInternalState({...internalState, [value]: !internalState[value]})}>
                    <span>{label}</span>
                </Checkbox>
            })}
        </div>
        <ButtonsRow>
            <button type="button" onClick={() => {
                setInternalState({});
            }}>Clear</button>
            <button type="button" onClick={(e) => {
                updateFilter(internalState);
                close(e);
            }}>Apply</button>
        </ButtonsRow>
    </div>
};
