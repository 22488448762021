import React from "react";
import _ from "lodash";
import loadingStyles from "./TableLoading.module.scss";
import classNames from "classnames";
import BlankRow from "./BlankRow";

const emptyRowState = {};
export default React.memo(({
                                   isLoading,
                                   rows,
                                   columns,
                                   RenderRow,
                                   rowClassName,
                                   lastColumnSticky,
                                   widths,
                                   totalWidth,
                                   cellClassName,
                                   selectionState,
                                   isRowSelectingEnabled,
                                   toggleRowSelection,
                                   rowState,
                                   updateRowState,
                                   tableWidth,
                                   onRowClick,
                                   onRowDoubleClick,
                                   lastColumnStickyStyleRef,
                                   stickyColumnStyleRef,
                                   stickColumnIndex,
                                   SubRowRenderer,
                                   isRowDisabled,
                                   disabledTitle
                               }) => {
    if (isLoading) {
        return _.range(5).map(row => {
            return <div key={row} className={loadingStyles['loading-row']}>
                {_.range(columns.length).map((column) => {
                    const style = {
                        flex: `1 0 ${widths[column]}px`
                    };

                    const includeMask = (_.sum(_.take(widths, column + 1)) < tableWidth) && column < 5;
                    return <div style={style} key={column} className={classNames({
                        [loadingStyles["loading-cell"]]: !includeMask,
                        [loadingStyles["loading-cell-with-mask"]]: includeMask
                    })} />
                })}
            </div>
        })
    } else {

        return <>
            <BlankRow stickColumnIndex={stickColumnIndex} stickyColumnStyleRef={stickyColumnStyleRef}
                      columns={columns} widths={widths} />
            {rows.map(
                (row, index) => {
                    const rowId = _.isUndefined(row.id) ? index : row.id;
                    const currentRowState = (rowState && rowState[rowId]) || emptyRowState;
                    const isRowSelected = selectionState && selectionState.rows[rowId];
                    return <RenderRow rowClassName={rowClassName}
                                      stickColumnIndex={stickColumnIndex}
                                      stickyColumnStyleRef={stickyColumnStyleRef}
                                      lastColumnSticky={lastColumnSticky}
                                      lastColumnStickyStyleRef={lastColumnStickyStyleRef}
                                      onRowClick={onRowClick}
                                      onRowDoubleClick={onRowDoubleClick}
                                      widths={widths}
                                      totalWidth={totalWidth}
                                      key={rowId}
                                      rowId={rowId}
                                      row={row}
                                      columns={columns}
                                      cellClassName={cellClassName}
                                      isRowSelected={isRowSelected}
                                      isMultiSelect={isRowSelectingEnabled && selectionState.isMulti}
                                      isRowSelectingEnabled={isRowSelectingEnabled}
                                      toggleRowSelection={toggleRowSelection}
                                      rowState={currentRowState}
                                      updateRowState={updateRowState}
                                      SubRowRenderer={SubRowRenderer}
                                      isRowDisabled={isRowDisabled}
                                      disabledTitle={disabledTitle}/>;
                }
            )}
        </>
    }
});
