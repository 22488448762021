import {useCallback, useReducer, useMemo, useRef} from "react";
import React from "react";
import _ from "lodash";

export const extractValue = (row, accessor) => {
    return _.get(row, accessor);
}

const sort = (sortState, columnsMap, rows) => {
    return _.orderBy(rows, [columnsMap[sortState.column].accessor], [sortState.sortOrder]);
};

const defaultFilter = (value, row, filter) => {
    if(_.isArray(value)){
        const existsOne = _.find(value, subValue => {
            return filter.values[subValue]
        });
        return !!existsOne;
    } else {
        return !!filter.values[value];
    }
}

const filter = (filterState, rows, columns, columnsMap) => {
    const {textFilter} = filterState;
    return _.filter(rows, (row) => {
        const lowerCaseTextFilter = textFilter && textFilter.toLowerCase();
        let isTextFilterMatch = !textFilter;

        if(textFilter) {
            _.forEach(columns, (column) => {
                const value = extractValue(row, column.accessor);

                if(_.isString(value) && value.toLowerCase().includes(lowerCaseTextFilter)) {
                    isTextFilterMatch = true;
                    return false;
                } else if (_.isArray(value) && value.join(", ").toLowerCase().includes(lowerCaseTextFilter)) {
                    isTextFilterMatch = true;
                    return false;
                }
            });
        }

        let isMultiFilterMatch = true;

        _.forEach(filterState.columns, (filter, key) => {
            if(_.isObject(filter) && filter.activeCount > 0) {
                const { accessor, filter: filterDefinition } = columnsMap[key];
                const value = extractValue(row, accessor);
                const filterFunc = filterDefinition?.filter || defaultFilter;
                const match = filterFunc(value, row, filter);
                if (!match) {
                    isMultiFilterMatch = false;
                    return false;
                }
            }
        });

        return isTextFilterMatch && isMultiFilterMatch;
    });
};

const init = (initialState) => {
    let sortState = null;

    if(initialState.isSorting) {
        sortState = {
            column: _.isString(initialState.columns[0]) ? initialState.columns[0] : (initialState.columns[0].id || "0"),
            sortOrder: "asc"
        }
    }

    const initialPageState = initialState.pageSize? {pageIndex: 0} : undefined;

    return {
        sortState: initialState.defaultSortState || sortState,
        defaultSortState: initialState.defaultSortState || sortState,
        defaultFilterState: initialState.defaultFilterState || {columns: {}, count: 0},
        tableState: {...initialState.defaultTableState} || {},
        rowState: {},
        columnState: {...(initialState.savedColumnState || initialState.defaultColumnState) } ,
        selectionState: initialState.isRowSelecting ? {rows: {}, count: 0, isMulti: (initialState.isMultiSelect !== false)} : undefined,
        filterState: initialState.defaultFilterState || {columns: {}, count: 0},
        pageState: initialPageState,
        initialPageState
    };
};

const reducer = (state, action) => {
    const {type, payload} = action;

    switch (type) {
        case "TOGGLE-SORT": {
            const { id } = payload;

            let newSortState = {};
            const currentSortOrder = state.sortState.sortOrder;
            const currentSortColumn = state.sortState.column;
            if(currentSortColumn === id){
                if(currentSortOrder === "asc") {
                    newSortState.sortOrder = "desc";
                    newSortState.column = id;
                } else if(currentSortColumn !== state.defaultSortState.column) {
                    newSortState = state.defaultSortState;
                } else {
                    newSortState.sortOrder = "asc";
                    newSortState.column = id;
                }
            } else {
                newSortState.sortOrder = "asc";
                newSortState.column = id;
            }

            return {...state, sortState: newSortState, pageState: state.initialPageState}
        }
        case "SET-FILTER": {
            const { id, values } = payload;

            const newValues = _.pickBy(values);

            const newColumnFilter = {
                values: newValues,
                valuesArray: _.values(values),
                activeCount: _.size(newValues)
            };

            const columns = {...state.filterState.columns, [id]: newColumnFilter};
            let count = 0;

            _.forEach(columns, (state) => {
                if(state.activeCount > 0) {
                    count = count + 1;
                }
            });

            return { ...state, filterState: {...state.filterState, columns, count}, pageState: state.initialPageState}
        }
        case "RESET-FILTERS": {
            return { ...state, filterState: {...state.filterState, ...state.defaultFilterState}, pageState: state.initialPageState}
        }
        case "UPDATE-ROW": {
            const {id, state: newState} = payload;
            const currentState = state.rowState[id] || {};

            return { ...state, rowState: {...state.rowState, [id]: {...currentState, ...newState}}}
        }
        case "UPDATE-COLUMN-STATE": {
            return { ...state, columnState: payload.state}
        }
        case "TOGGLE-ROW-SELECTION": {
            const {id, value} = payload;

            if(state.selectionState.isMulti) {
                let count = state.selectionState.count;
                const currentValue = state.selectionState.rows[id] || false;
                if(currentValue && !value) {
                    count = count -1;
                } else if (!currentValue && value) {
                    count = count + 1;
                }

                return {...state, selectionState: {count, rows: {...state.selectionState.rows, [id]: value}, isMulti: true}};
            } else {
                if(!value) {
                    return state;
                } else {
                    return {...state, selectionState: {count: 1, rows: {[id]: true}, isMulti: false}}
                }
            }
        }
        case "TOGGLE-SELECT-ALL": {
            const { value, rows } = payload;

            if(!state.selectionState.isMulti){
                return state;
            }

            const selectionState = {rows: {}, count: 0, allSelected: value, isMulti: true};
            if(value) {
                _.forEach(rows, (row) => {
                    selectionState.rows[row.id] = value;
                });
                selectionState.count = rows.length
            }
            return {...state, selectionState};
        }
        case "UPDATE-TEXT-FILTER": {
            const {value} = payload;
            return {...state, filterState: {...state.filterState, textFilter: value}, pageState: state.initialPageState}
        }
        case "GO-TO-PAGE": {
            return {...state, pageState: {pageIndex: payload.index}}
        }
    }

    return state;
};

export default ({isSorting, isRowSelecting, isMultiSelect, columns, rows: data, externalSort, externalFilter, defaultSortState, pageSize, defaultColumnState, savedColumnState }) => {
    const processedRows = useMemo(() => {
        return _.map(data, (row, index) => ({...row, id: _.isUndefined(row.id) ? index : row.id}))
    }, [data]);

    const {pColumns, columnsMap} = useMemo(() => {
        const columnsMap = {};

        const pColumns = columns.map((column, index) => {
            let pColumn = null;
            if(_.isString(column)) {
                pColumn = {id: column, accessor: column}
            } else {
                pColumn = {...column, id: _.isUndefined(column.id) ? index.toString() : column.id};
            }
            columnsMap[pColumn.id] = pColumn;
            return pColumn;
        });

        return {pColumns, columnsMap}
    }, [columns]);

    const [table, dispatch] = useReducer(reducer, {columns, isSorting, isRowSelecting, isMultiSelect, defaultSortState, pageSize, defaultColumnState, savedColumnState}, init);
    const tableRef = useRef(null);
    tableRef.current = table;

    const shownColumns = useMemo(() => {
        return pColumns.filter(column => {
            return !table.columnState[column.id]?.isHidden;
        });
    }, [pColumns, table.columnState]);

    const toggleSort = useCallback(({id}) => {
        dispatch({
            type: "TOGGLE-SORT",
            payload: {
                id
            }
        })
    }, [dispatch]);

    const updateFilterState = useCallback((payload) => {
        dispatch({
            type: "SET-FILTER",
            payload
        })
    }, [dispatch]);

    const resetFilters = useCallback(() => {
        dispatch({
            type: "RESET-FILTERS"
        })
    }, [dispatch]);

    const updateRowState = useCallback( id => stateOrFunc => {
        let state = _.isFunction(stateOrFunc) ? stateOrFunc(tableRef.current.rowState[id]) : stateOrFunc;

        dispatch({
            type: "UPDATE-ROW",
            payload: {
                id,
                state
            }
        })
    }, [dispatch]);

    const updateColumnState = useCallback( state => {
        dispatch({
            type: "UPDATE-COLUMN-STATE",
            payload: {
                state
            }
        })
    }, [dispatch]);

    const restoreColumnStateDefaults = useCallback( () => {
        updateColumnState(defaultColumnState || {});
    }, [updateColumnState]);

    const updateTextFilter = useCallback((value) => {
        dispatch({
            type: "UPDATE-TEXT-FILTER",
            payload: {
                value
            }
        })
    }, [dispatch]);

    const filteredRows = useMemo(() => {
        if((table.filterState.count > 0 || table.filterState.textFilter) && !externalFilter) {
            return filter(table.filterState, processedRows, columns, columnsMap)
        } else {
            return processedRows;
        }
    }, [table.filterState, processedRows, externalFilter, columns, columnsMap]);

    const organizedRows = useMemo(() => {
        if(isSorting && !externalSort) {
            return sort(table.sortState, columnsMap, filteredRows);
        } else {
            return filteredRows;
        }
    }, [isSorting, externalSort, filteredRows, table.sortState, columnsMap]);

    const toggleRowSelection = useCallback(id => value => {
        dispatch({
            type: "TOGGLE-ROW-SELECTION",
            payload: {
                id,
                value
            }
        })
    }, [dispatch]);

    const selectAllRows = useCallback(value => {
        dispatch({
            type: "TOGGLE-SELECT-ALL",
            payload: {
                value,
                rows: organizedRows
            }
        })
    }, [dispatch, organizedRows]);

    const pageChunks = useMemo(() => {
        if(pageSize) {
            return _.chunk(organizedRows, pageSize);
        } else {
            return null;
        }
    }, [organizedRows, pageSize]);

    const pagination = useMemo(() => {
        if(pageChunks) {
            const pageIndex = table.pageState.pageIndex;
            const pageCount = pageChunks.length;
            const gotoPage = (i) => {
                if(i >= 0 && (i < pageCount)) {
                    dispatch({
                        type: "GO-TO-PAGE",
                        payload: {
                            index: i
                        }
                    })
                }
            };
            return {
                pageIndex,
                pageRows: pageChunks[pageIndex],
                pageCount,
                gotoPage
            }
        } else {
            return null;
        }
    }, [pageChunks, table.pageState, dispatch]);

    return useMemo(() => ({
        rows: processedRows,
        organizedRows,
        columns: shownColumns,
        sortState: table.sortState,
        filterState: table.filterState,
        tableState: table.tableState,
        rowState: table.rowState,
        selectionState: table.selectionState,
        columnState: table.columnState,
        toggleSort,
        updateFilterState,
        updateRowState,
        updateTextFilter,
        toggleRowSelection,
        selectAllRows,
        resetFilters,
        pagination,
        updateColumnState,
        restoreColumnStateDefaults
    }), [table, organizedRows, toggleSort, updateFilterState, updateRowState, updateTextFilter, toggleRowSelection, selectAllRows, processedRows, resetFilters, pagination, updateColumnState, restoreColumnStateDefaults])
};
