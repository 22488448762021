import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './styles/app/globals.scss';
import App from './App';
import {Provider} from 'react-redux';
import store, {history} from './store';
import * as serviceWorker from './serviceWorker';
import {Route, Router} from 'react-router-dom';
import axios from 'axios';


axios.defaults.baseURL = window.__react_app_portal_backend_base_url;
window.axios = axios;

store.dispatch({type: 'AUTH:REFRESH-SSO'});
ReactDOM.render(<Provider store={store}>
    <Router history={history}>
        <Route path="/" component={App}/>
    </Router>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
