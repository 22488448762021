
const defaultState = () => ({
    error: null,
    isTableLoaded: false,
    tableData: [],
});


export default (state = defaultState(), {payload, type}) => {
    switch (type){
        case "TABLEACCOUNTS:LOAD-DATA-SUCCESS": {
            let summeryRow = payload.data.pop();
            summeryRow.id=1
            summeryRow=[summeryRow]
            return { ...state, isTableLoaded: true, error: false, tableData: payload, summeryRow: summeryRow }
        }
        case "TABLEACCOUNTS:LOAD-DATA-FAILURE": {
            return {...state,isTableLoaded:true,error:payload.response.data.message||true}
        }
        case "ORGANIZATIONS:CHANGEORG": {
            return {...state,isTableLoaded:false}
        }
        default:
            break;
    }
    return state;
}
