const defaultState = {
    accountTab: false,
    navBarTab: false,
    theme: 'light',
    tab: 'cost'
};

const styleReducer = (state = defaultState, {payload, type}) => {
    switch (type) {
        case 'getPageStyle':
            return {...state, accountTab: true, navBarTab: false};
        case 'accountTab:open': {
            return {...state, accountTab: true, navBarTab: false};
        }
        case 'tabs:close': {
            return {...state, accountTab: false, navBarTab: false};
        }
        case 'navBar:open': {
            return {...state, navBarTab: true, accountTab: false};
        }
        case 'navBar:close': {
            return {...state, navBarTab: false, accountTab: false};
        }
        case 'changeTheme':
            return {...state, theme: payload};
        case 'changeTab':
            return {...state, accountTab: false, navBarTab: false, tab: payload};
        default:
            break;
    }
    return state;
};

export default styleReducer;
