import _ from "lodash";
import classNames from "classnames";
import styles from "./Table.module.scss";
import { Checkbox } from "../Forms";
import Popover from "../Popover";
import { ReactComponent as SortUpIcon } from "./ic-table-sort-asc.svg";
import { ReactComponent as SortDownIcon } from "./ic-table-sort-desc.svg";
import { InternalFilter } from "./InternalTableFilters";
import React from "react";


const RenderHeaderCell = ({
                              sortState,
                              column,
                              toggleSort,
                              width,
                              columnIndex,
                              columnFilterOptions,
                              columnFilterState = {},
                              updateFilterState,
                              selectionState,
                              selectAllRows,
                              stickLastColumn,
                              lastColumnStickyStyleRef,
                              stickyColumnStyleRef,
                              stickColumn,
                              headerCellClassName,
                              headerHandleClassName
                          }) => {
    const id = column.id;
    const sortingEnabled = !!sortState && _.get(column, "sort.enabled", true);
    const filterEnabled = _.get(column, "filter.enabled", false);
    const selectingEnabled = selectionState && columnIndex === 0;
    const isSorted = sortingEnabled && sortState.column === id;
    const sortOrder = sortingEnabled && isSorted && sortState.sortOrder;
    const hideHeaderHandle = _.get(column, "hideHeaderHandle", false);

    const className = classNames(column.headerClass, styles['header-cell'], headerCellClassName, {
        [styles['is-sorting']]: sortingEnabled,
        [styles['is-selecting']]: selectingEnabled && selectionState.isMulti,
        [styles['is-selecting-single']]: selectingEnabled && !selectionState.isMulti,
        [lastColumnStickyStyleRef.current.dataset.styleid]: stickLastColumn,
        [stickyColumnStyleRef.current.dataset.styleid]: stickColumn
    });
    const style = {
        flex: column.fixedWidth ? `0 0 ${width}px` : `1 0 ${width}px`
    };
    const onClick = () => {
        sortingEnabled && toggleSort({ id })
    };

    const updateColumnFilter = (values) => {
        updateFilterState({
            id,
            values
        });
    };

    return <div
        className={className}
        onClick={onClick}
        style={style}>

        {selectingEnabled && selectAllRows && <Checkbox checked={selectionState?.allSelected || false} onChange={() => {
            selectAllRows(!selectionState.allSelected)
        }} className={styles['header-is-selecting-indicator']} />}
        {column.popoverText && <Popover triggerClassName={styles['header-cell-popover-trigger']} triggerOnClick
                                        white>{column.popoverText}</Popover>}
        <span
            className={styles['header-label']}>{column.header}{filterEnabled && columnFilterState.activeCount > 0 && ` (${columnFilterState.activeCount})`}</span>
        {sortingEnabled &&
        <span className={styles['sort-icons']}>
                <SortUpIcon className={classNames({ [styles['sort-icon-active']]: sortOrder === "asc" && isSorted })} />
                <SortDownIcon className={classNames({ [styles['sort-icon-active']]: sortOrder === "desc" && isSorted })} />
            </span>
        }
        {filterEnabled && <InternalFilter filterConfig={column.filter} columnFilterOptions={columnFilterOptions}
                                          columnFilterState={columnFilterState}
                                          updateColumnFilter={updateColumnFilter} />}
        {!hideHeaderHandle && <div className={classNames(styles['header-handle'], headerHandleClassName)} />}
    </div>
};


export  default  RenderHeaderCell
