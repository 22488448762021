import React, {useState, useCallback} from 'react';
import classNames from "classnames";
import {InlineLoader} from "./../Loaders";

import styles from "./FormFieldInputNew.module.scss";

export const LabelChildren = ({charCount, counter, maxChars, isOptional, labelChildren}) => {
    return <div className={styles["label-children"]}>
        {charCount && <span>{counter}/{maxChars}</span>}
        {isOptional && <span className={styles["input-optional"]}>Optional</span>}
        {labelChildren}
    </div>
};

export const Error = ({showError, errorClassName, errorStyle, errorMessage}) => {
    if (showError) {
        return <div className={classNames(styles.error, errorClassName)} style={errorStyle}><span>Error:</span> {errorMessage}</div>
    } else {
        return "";
    }
};

export const Formidable = React.forwardRef((props, ref) => {
    const {
        Component,
        form,
        name,
        onChange: _onChange,
        value: _value,
        errorMessage: _errorMessage,
        showError: _showError,
        onBlur: _onBlur,
        useHandleFormChangeWrapper
    } = props;
    const [wasFocused, setWasFocused] = useState(false);
    const {onChange, value, errorMessage, showError} = formHandler({
        _onChange,
        _value,
        _errorMessage,
        _showError,
        form,
        name,
        wasFocused,
        useHandleFormChangeWrapper
    });

    const onBlur = useCallback((e) => {
        setWasFocused(true);
        _onBlur && _onBlur(e);
    }, [setWasFocused, _onBlur]);

    return <Component onBlur={onBlur} {...props} form={undefined} onChange={onChange} value={value}
                      errorMessage={errorMessage} showError={showError} ref={ref}/>
});

export const formHandler = ({
                                _onChange,
                                _value,
                                _showError,
                                _errorMessage,
                                form,
                                name,
                                useHandleFormChangeWrapper,
                                wasFocused
                            }) => {
    let onChange = _onChange;
    let value = _value;
    let errorMessage = _errorMessage;
    let showError = _showError;
    if (form && name) {
        value = _value || form.formState[name];
        onChange = _onChange || (useHandleFormChangeWrapper ? form.wrappedHandleFormChange(name) : form.handleFormChange);
        errorMessage = _errorMessage || form.formErrors[name];
        if(_.isUndefined(_showError)) {
            showError = (form.submissionAttempted || (form.dirty[name] && wasFocused)) && form.formErrors[name]
        }
    }

    return {onChange, value, errorMessage, showError};
};

const Base = React.forwardRef((props, ref) => {
    const {
        Component,
        isOptional, label, labelChildren, useLabelMargin,
        type, name, onChange, value, errorMessage, showError, disabled,
        maxChars, charCount,
        inputChildren, inputButtons, inputChildrenClassName, inputChildrenStyle,
        className, style, inputStyle, labelStyle, inputClassName, labelClassName, errorClassName, errorStyle,
        pending,
        ...rest
    } = props;

    const [counter, setCounter] = useState((value && value.length) || 0);

    const handleInput = (e) => {
        if (maxChars && (e.target.value.length > maxChars)) {
            return;
        }
        setCounter(e.target.value.length);
        onChange(e);
    };
    return (
        <div
            className={classNames(styles["input-container"], className, {[styles["use-label-margin"]]: useLabelMargin})}
            style={style}>
            <LabelChildren charCount={charCount} counter={counter} maxChars={maxChars} isOptional={isOptional}
                           labelChildren={labelChildren}/>
            <label className={(showError) ? styles["invalid"] : ''}>
                {label && <span className={classNames(labelClassName, {[styles["disabled"]]: disabled})} style={labelStyle}>{label}</span>}
                <div className={styles['input-wrapper']}>
                    {pending && !inputButtons && <InlineLoader className={styles["inline-loader"]}/>}
                    {inputChildren && !inputButtons && <div className={classNames(styles["input-children"], inputChildrenClassName)} style={inputChildrenStyle}>{inputChildren}</div>}
                    <Component type={type ? type : "text"} {...rest} name={name} value={value} ref={ref}
                               onChange={handleInput} className={classNames(inputClassName, {[styles['has-input-buttons']]: !!inputButtons})} style={inputStyle}
                               disabled={disabled}/>
                    {inputButtons && <div className={styles['input-buttons']}>
                        {pending && <InlineLoader className={classNames(styles["inline-loader-inside"])}/>}
                        {inputChildren && <div className={classNames(styles["input-children-inside"], inputChildrenClassName)} style={inputChildrenStyle}>{inputChildren}</div>}
                        {inputButtons}
                    </div>}
                </div>
            </label>
            <Error showError={showError} errorClassName={errorClassName} errorStyle={errorStyle} errorMessage={errorMessage}/>
        </div>
    );
});

const FormFieldInline_ = React.memo(React.forwardRef((props, ref) => <Base {...props} Component="input" ref={ref}/>));

export const FormFieldInline = React.forwardRef((props, ref) => {
    return <Formidable {...props} className={classNames(props.className, styles['inline-input'])} ref={ref} Component={FormFieldInline_}/>
});

const FormFieldTextArea_ = React.memo(React.forwardRef((props, ref) => <Base {...props} Component="textarea" ref={ref}/>));

export const FormFieldTextArea = React.forwardRef((props, ref) => {
    return <Formidable {...props} ref={ref} Component={FormFieldTextArea_}/>
});

const FormFieldInput_ = React.memo(React.forwardRef((props, ref) => <Base {...props} Component="input" ref={ref}/>));

export default React.forwardRef((props, ref) => {
    return <Formidable {...props} ref={ref} Component={FormFieldInput_}/>
});
