import React, {useEffect, useState} from "react";
import classNames from "classnames";
import styles from "./CopyButton.module.scss";
import buttons from "./../buttons.module.scss";
import {ReactComponent as CopyIcon} from "./../../images/monochrome-icons/ic-copy.svg";
import {ReactComponent as BubbleIcon} from "./../../images/monochrome-icons/ic-bubble.svg";
import {CSSTransition} from "react-transition-group";
import ReactDOM from "react-dom";
import {CopyToClipboard} from "react-copy-to-clipboard";
import _ from "lodash";

export default ({text: _text, className, style, children, type = "default", includeIcon}) => {
    const [showBubble, setShowBubble] = useState(false);
    const [bubblePosition, setBubblePosition] = useState({});
    const buttonRef = React.createRef();
    const bubbleRef = React.createRef();
    const text = _.isObject(_text) ? JSON.stringify(_text, null, 4) : _text;

    useEffect(() => {
        if(showBubble && buttonRef.current){
            const {top, left, width} = buttonRef.current.getBoundingClientRect();
            const maxWidth = document.body.clientWidth;
            let isLeft = false;
            if(left + width + 60 > maxWidth) {
                isLeft = true;
            }

            setBubblePosition({top: top - 31, left: left + (isLeft ? 0 : width), isLeft})
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBubble]);

    let baseClassName = styles['button'];
    if(type === "secondary") {
        baseClassName = buttons.secondary
    } else if (type === "primary") {
        baseClassName = buttons.primary;
    }

    if(type === "default" && _.isUndefined(includeIcon)) {
        includeIcon = true;
    }

    return <CopyToClipboard text={text} onCopy={() => {
            setShowBubble(true);
            setTimeout(() => {
                setShowBubble(false);
            })
        }}>
            <button type="button" ref={buttonRef} className={classNames(className, baseClassName)} style={style}>
                {includeIcon && <CopyIcon className={classNames({[styles['with-children']]: !!children})}/>}
                {children}
                <CSSTransition nodeRef={bubbleRef} in={showBubble} unmountOnExit timeout={1000}>
                    <span ref={bubbleRef}>
                        {ReactDOM.createPortal(<span className={classNames(styles['bubble'], {[styles['left']]: bubblePosition.isLeft})} style={{top: bubblePosition.top, left: bubblePosition.left}}><BubbleIcon/><span>Copied!</span></span>, document.body)}
                    </span>
                </CSSTransition>
            </button>
    </CopyToClipboard>
};
