import React from "react";
import FormFieldInputNew from "./FormFieldInputNew";
import classNames from "classnames";
import styles from "./FormFieldUploadFile.module.scss";
import {readFileFromEvent} from "../../utils";
import {ReactComponent as UploadSvg} from "ui-components-rollup/src/images/monochrome-icons/ic-upload.svg";


export default ({form, hideText, name, disabled, className, label, placeholder, showError: _showError}) => {
    const showError = !_.isUndefined(_showError) ? _showError : (form.submissionAttempted && form.formErrors[name]);

    const readFile = async (e) => {
        const file = await readFileFromEvent(e);
        form.handleFormChange(file, {
            name
        });
        e.target.value = ''
    }
    return <div className={classNames(styles.base, className)}>
        <FormFieldInputNew
            type="text"
            errorMessage={form.formErrors[name]}
            showError={showError}
            name={name}
            label={label}
            value={form.formState[name]?.fileName || ""}
            disabled={true}
            placeholder={placeholder}
            className={styles["input-container"]}
            inputClassName={styles.input}
            labelClassName={disabled ? null: styles.label}
        />
        <div
            className={classNames({[styles["disabled"]]: disabled}, styles['button-wrapper'])}>
            <input disabled={disabled} type="file" onChange={readFile}/>
            <UploadSvg className={styles['svg']}/>
            {!hideText && <div  className={styles['text']}>Upload</div>}
        </div>
    </div>

};