import React, {useEffect, useState, useRef, useMemo} from 'react';
import styles from "./FormFieldSelect.module.scss";
import classNames from "classnames";
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';
import _ from "lodash";
import colors from "./../colors.scss"
import typography from "./../typography.module.scss"
import {Error, LabelChildren, Formidable} from "./FormFieldInputNew";
import { VariableSizeList  } from "react-window";

const getSelectStyles = (hasError, selectStyles) => ({
    clearIndicator: (base, state) => ({ ...base, padding: 4, display: state.getValue().length === 1 && 'none' }),
    control: (base, state) => {
        return ({
            ...base,
            minHeight: 40,
            borderColor:  `${state.menuIsOpen ? colors.primaryBlue : (hasError ? colors.errorRed : colors.inputFieldBorder)} !important`,
            boxShadow: "none",
            borderWidth: hasError || state.isFocused ? "2px" : "1px",
            margin: 0,
            fontSize: 14
        })
    },
    dropdownIndicator: base => ({ ...base, padding: 4 }),
    input: base => ({ ...base, margin: 0, padding: 0 }),
    menu: base => ({ ...base, fontSize: 13, marginTop: 0, zIndex: 3, boxShadow: "0 1px 3px 0 rgba(0,0,0,0.2)", borderRadius: 2 }),
    menuList: base => ({ ...base, maxHeight: 225, paddingTop: 3, paddingBottom: 0 }),
    menuPortal: base => ({ ...base, zIndex: 99999999999999999}),
    placeholder: (base) => ({ ...base, fontSize: 14, color: "#a7a7a7" }),
    singleValue: base => ({ ...base, color: colors.textGrey, maxWidth: 'calc(100% - 30px)' }),
    valueContainer: base => ({ ...base, padding: '0px 15px' }),
    option: base => ({...base, padding: "11px 16px", lineHeight: "1.4", cursor: "pointer"}),
    ...selectStyles
});

const OptimizedMenuList = (props) => {
    const { options, children, maxHeight, getValue } = props;
    if (!children || !_.isArray(children) || children.length < 10) return <components.MenuList {...props}/>

    const getItemSize = (index) => {
        if(children[index]?.props?.data?.secondaryLabel) {
            return doubleRowHeight;
        } else {
            return singleRowHeight;
        }
    };

    const singleRowHeight = 40;
    const doubleRowHeight = 62;
    const selectedValues = getValue();
    const selectedIndex = selectedValues[0] ? options.indexOf(selectedValues[0]) : 0;
    const initialOffset = selectedIndex * getItemSize(selectedIndex); //Assumes every row is same height

    return (
        <VariableSizeList
            itemSize={getItemSize}
            height={maxHeight}
            itemCount={children.length}
            initialScrollOffset={initialOffset}
        >
            {({ index, style }) => (
                <div style={style}>
                    {children[index]}
                </div>
            )}
        </VariableSizeList >
    )
}

const defaultOptions = {
    Option: props => {
        if(props.data.__isNew__) {
            return <components.Option {...props} className={styles['create-new-option']}/>
        } else {
            return <div title={props.data.title}>
                <components.Option {...props} className={classNames(styles['select-option'], {[styles['active-option']]: props.data.value === props.selectProps.value?.value, [styles['two-label-option']]: props.data.secondaryLabel})}>
                    {!props.data.secondaryLabel && props.data.label} {!props.data.secondaryLabel && props.data.subLabel && <span className={styles['sub-label']}>{props.data.subLabel}</span>}
                    {props.data.secondaryLabel && <>
                        <div className={typography.b}>{props.data.label}</div>
                        <div>{props.data.secondaryLabel}</div>
                    </>}
                </components.Option>
            </div>
        }},
    MultiValueRemove: props => {
        if(props.data.noRemove) {
            return <span/>
        } else {
            return <components.MultiValueRemove {...props}/>
        }
    },
    IndicatorSeparator: () => ""
}

const virtualizedOptions = {
    ...defaultOptions,
    MenuList: OptimizedMenuList
}

export const getOptions = (virtualize) => {
    if(virtualize) {
        return virtualizedOptions;
    } else {
        return defaultOptions;
    }
};

const defaultComponents = getOptions;

const Base = React.forwardRef(({
                                   Component,
                                   isOptional, label, labelChildren, useLabelMargin,
                                   name, onChange, value, errorMessage, showError, disabled, options, isLoading,
                                   maxChars, charCount,
                                   className, style, selectStyle, errorClassName, errorStyle, _components,
                                   virtualize,
                                   ...rest}, ref) => {
    const [counter, setCounter] = useState(0);
    const saveRef = useRef({});
    saveRef.current = { isLoading, value }

    useEffect(() => {
        if(charCount) {
            setCounter(_.sumBy(value, "value.length"));
        }
    }, [value, charCount]);

    const handleInput = (option, e) => {
        if(charCount && _.isArray(option)){
            if (charCount && (_.sumBy(option, "value.length") > maxChars)) {
                return;
            }
            onChange(option, e);
        } else {
            return onChange(option, e);
        }
    };

    const components = useMemo(() => {
        return _components || defaultComponents(virtualize);
    }, [_components, virtualize]);

    return <div className={classNames(styles["select-container"], className)} style={style}>
        <LabelChildren charCount={charCount} counter={counter} maxChars={maxChars} isOptional={isOptional} labelChildren={labelChildren}/>
        <label className={showError && errorMessage ? styles["invalid"] : ''}>
            {label && <span className={classNames({[styles["disabled"]]: disabled})}>{label}</span>}
            <Component
                {...rest}
                options={options}
                isLoading={isLoading}
                styles={getSelectStyles(showError && errorMessage, selectStyle)}
                components={components}
                ref={ref}
                name={name}
                isDisabled={disabled}
                onChange={handleInput}
                value={value}
            />
        </label>
        <Error showError={showError} errorClassName={errorClassName} errorStyle={errorStyle} errorMessage={errorMessage}/>
    </div>
});

const CreateLabel = value => {
    return <div className={styles['create-new']}>
        <svg width="22px" height="22px" viewBox="0 0 22 22">
            <g transform="translate(-1637.000000, -459.000000)">
                <g transform="translate(395.000000, 442.000000)">
                    <g id="Add_ic" transform="translate(1242.000000, 17.000000)">
                        <polygon fillRule="nonzero" points="10.6666667 7 10.6666667 10.6666667 7 10.6666667 7 11.3333333 10.6666667 11.3333333 10.6666667 15 11.3333333 15 11.3333333 11.3333333 15 11.3333333 15 10.6666667 11.3333333 10.6666667 11.3333333 7"></polygon>
                    </g>
                </g>
            </g>
        </svg>
        Create New "<span className={typography.b}>{value}</span>"</div>
};

const FormFieldCreatable_ = React.memo(React.forwardRef((props, ref) => {
    return <Base {...props} Component={Creatable} ref={ref}/>
}));

export const FormFieldCreatable = React.forwardRef((props, ref) => {
    return <Formidable {...props} useHandleFormChangeWrapper={true} formatCreateLabel={CreateLabel} ref={ref} Component={FormFieldCreatable_}/>
});


const FormFieldSelect_ = React.memo(React.forwardRef((props, ref) => <Base {...props} Component={Select} ref={ref}/>));

export default React.forwardRef((props, ref) => {
    return <Formidable {...props} ref={ref} Component={FormFieldSelect_}/>
});
