import React from "react";
import styles from "./TransitionChevron.module.scss";
import classNames from "classnames";

export default React.memo(({isActive, className, style, color, thin, wide, medium}) => {
    return <span className={classNames(styles['base'], className, {[styles[color]]: color, [styles.thin]: thin, [styles.wide]: wide, [styles.medium]: medium})} style={style}>
        <span className={classNames(styles['left'], {[styles['left-active']]: isActive})}/>
        <span className={classNames(styles['right'], {[styles['right-active']]: isActive})}/>
    </span>
});
