import { useSelector } from 'react-redux';
import TabLinks  from "./Tab/TabLinks";
import TabHeader from "./Tab/TabHeader";
import { NavLink } from 'react-router-dom';
import styles from './MainHeader.module.scss';
import { ReactComponent as CloudIcon } from "ui-components-rollup/src/images/2-colors-icons/ic-sales-enablement.svg"
import { ReactComponent as MonitorIcon } from "ui-components-rollup/src/images/2-colors-icons/ic-user-card.svg"


const LinksTab = () => {

    const currentTab = useSelector(state => state.style.tab)

    return <div className={styles['tab-style']}>
        {currentTab === 'cost' &&
            <TabHeader icon={CloudIcon} label="Cost">
                <TabLinks>
                    <NavLink to="/cost-chart">Chart</NavLink>
                    <NavLink to="/cost-map">Map</NavLink>
                    <NavLink to="/cost-table-regions">Services AWS</NavLink>
                    <NavLink to="/cost-table-accounts">Accounts AWS</NavLink>
                    <NavLink to="/cost-table-regions-azure">Services Azure</NavLink>
                </TabLinks>
            </TabHeader>
        }
        {currentTab === 'security' &&
            <TabHeader icon={MonitorIcon} label="security">
                <TabLinks>
                    <NavLink to="/guard-duty">Guard Duty</NavLink>
                    <NavLink to="/config-service">Config service AWS</NavLink>
                    <NavLink to="/azure-advisor">Azure Advisor</NavLink>
                </TabLinks>
            </TabHeader>
        }
    </div>
}
export default LinksTab