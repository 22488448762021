import React, { useCallback, useMemo, useReducer, useRef } from 'react';
import _ from "lodash";

const noValidator = () => ({});

export const yupValidator = schema => state => {
    const errors = {};
    try {
        schema.validateSync(state, {
            abortEarly: false
        });
    } catch (e) {
        e.inner && _.forEach(e.inner, ({ path, message }) => {
            errors[path] = errors[path] || message;
        });
    }
    return errors;
};

const init = (state) => {
    return {
        fields: state,
        isValid: false,
        isDirty: false,
        dirty: {},
        errorState: {},
        submissionAttempted: false,
    }
}

const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case "RESET": {
            return {
                ...state,
                fields: payload.fields,
                dirty: {},
                isDirty: false
            }
        }
        case "MERGE": {
            const { fields, dirty } = payload;
            return { ...state, fields: { ...state.fields, ...fields }, dirty: { ...state.dirty, ...dirty }, isDirty: true }
        }
        case "SUBMISSION-ATTEMPTED": {
            return { ...state, submissionAttempted: true }
        }
        case "RESET-SUBMISSION-ATTEMPTED": {
            return { ...state, submissionAttempted: false }
        }
        case "VALIDITY": {
            return { ...state, isValid: payload.isValid, errorState: payload.errorState }
        }
    }

    return state;
};

export default (initialState, validate = noValidator) => {
    const [state, dispatch] = useReducer(reducer, initialState, init);
    const stateRef = useRef({});

    const {isValid, errorState} = useMemo(() => {
        const validator = validate.validateSync ? yupValidator(validate) : validate;
        const errors = validator(state.fields);
        return {isValid: _.isEmpty(errors), errorState: errors};
    }, [validate, state.fields]);

    stateRef.current = { state, isValid };

    const resetForm = useCallback(state => {
        dispatch({
            type: "RESET",
            payload: {
                fields: state
            }
        });
    }, [dispatch]);

    const resetSubmissionAttempted = useCallback(() => {
        dispatch({
            type: "RESET-SUBMISSION-ATTEMPTED"
        })
    }, [dispatch]);

    const handleFormSubmit = useCallback(callback => event => {
        dispatch({
            type: "SUBMISSION-ATTEMPTED"
        })
        event.preventDefault();

        if (stateRef.current.isValid) {
            callback(event, stateRef.current.state.fields);
        }
    }, [dispatch]);

    const handleFormChange = useCallback((_valueOrEvent, _Event) => {
        if (_.isArray(_valueOrEvent) && !_Event) {
            const mergeState = {};
            const mergeDirty = {};
            _.forEach(_valueOrEvent, ({ value, event }) => {
                mergeState[event.name] = value;
                mergeDirty[event.name] = true;
            });

            dispatch({
                type: "MERGE",
                payload: {
                    fields: mergeState,
                    dirty: mergeDirty
                }
            });
        } else {
            let name = null;
            let value = null;

            if (_Event) {
                name = _Event.name;
                value = _valueOrEvent;
            } else {
                const event = _valueOrEvent;
                if (event.target.type === "checkbox") {
                    value = event.target.checked;
                    name = event.target.value;
                } else {
                    value = event.target.value;
                    name = event.target.name;
                }
            }

            if (name) {
                dispatch({
                    type: "MERGE",
                    payload: {
                        fields: {
                            [name]: value
                        },
                        dirty: {
                            [name]: true
                        }
                    }
                });
            } else {
                console.warn("Form event received without form name. Value ignored.");
            }
        }
    }, [dispatch]);

    const wrappedHandleFormChange = useCallback((name) => (value, event) => {
        handleFormChange(value, { ...event, name });
    }, [handleFormChange]);

    return useMemo(() => {
        return { resetForm, handleFormSubmit, handleFormChange, wrappedHandleFormChange, formState: state.fields, dirty: state.dirty, isDirty: state.isDirty, isValid, formErrors: errorState, submissionAttempted: state.submissionAttempted, resetSubmissionAttempted }
    }, [resetForm, handleFormSubmit, handleFormChange, wrappedHandleFormChange, state, isValid, errorState, resetSubmissionAttempted]);
}
