import React from "react";
import styles from "./TabLinks.module.scss"
import _ from "lodash";
import classNames from "classnames";

export default ({children: _children = []}) => {
    const children = _.isArray(_children) ? _children : [_children];

    return <div className={styles['container']}>
        {children.map((child, index) => {
            if(child) {
                return React.cloneElement(child, {key: index, activeClassName: styles['active-link'], className: classNames(styles['link'], child.props?.className)}, child.props?.children);
            } else {
                return child;
            }
        })}
    </div>
};

