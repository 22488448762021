import styles from "./HorizontalInnerNav.module.scss";
import classNames from "classnames";
import _ from "lodash";
import React from "react";

const HorizontalInnerNav = React.memo(({children: _children = [], className, style, expand = false}) => {
    const children = _.isArray(_children) ? _children : [_children];

    return <div className={classNames(styles.base, className, {[styles.expand]: expand})} style={style}>
        {children.map((child, index) => {
            if(!child) {
                return child
            } else {
                const _activeClassName = classNames(styles['active'], child.props?.activeClassName);
                return React.cloneElement(child, {key: index, activeClassName: _activeClassName, className: classNames(styles['link'], child.props?.className, {[_activeClassName]: child.props?.isActive})}, child.props?.children);
            }
        })}
    </div>
});

export default HorizontalInnerNav;
