import styles from "./../buttons.module.scss"
import tooltips from "./../tooltips.module.scss";
import React from "react";
import classNames from "classnames";
import { InlineLoader } from "../Loaders";

export const getBaseClassName = ({type, color}) => {
    let baseClassName;

    if(!type) {
        if(color === "secondary") {
            baseClassName = styles.secondary
        } else if (color === "error") {
            baseClassName = styles.error
        } else {
            baseClassName = styles.primary;
        }
    } else if (type === "inline") {
        if(color === "secondary") {
            baseClassName = styles['inline-text-secondary'];
        } else {
            baseClassName = styles['inline-text'];
        }
    } else if (type === "text") {
        if(color === "secondary") {
            baseClassName = styles['text-secondary'];
        } else {
            baseClassName = styles.text;
        }
    } else if (type === "icon") {
        if(color === "light-grey") {
            baseClassName = styles['icon-light'];
        } else if (color === "dark-grey") {
            baseClassName = styles['icon-dark']
        } else {
            baseClassName = styles['icon'];
        }
    } else if (type === "none") {
        baseClassName = "";
    }

    return baseClassName;
}

const Button = ({children, className, style, color, type, large, disabled, isSubmitting, tooltip, tooltipLeft, submit, ...rest}) => {
    const baseClassName = getBaseClassName({type, color});

    const _className = classNames(baseClassName, className, {[styles.large]: large});
    return <button type={submit ? "submit" : "button"} disabled={isSubmitting || disabled} className={_className} {...rest} style={style}>
        {isSubmitting ? <InlineLoader className={styles.loader} type={color === "secondary" ? "default" : "white"}/> : children}
        {tooltip && <div className={classNames(styles.tooltip, tooltips.small, {[styles["tooltip-left"]]:tooltipLeft})}>{tooltip}</div>}
    </button>
}

export default Button;
