import React from 'react';
import styles from './CheckComponent.module.scss';
import {ReactComponent as CheckIcon} from "../../images/monochrome-icons/ic-check.svg";
import {ReactComponent as ViHoverIcon} from "../../images/monochrome-icons/vi-hover.svg";
import classNames from "classnames";

const CheckComponent = ({selected, className, hovered}) => {
    return <div className={classNames(styles['check-component'], className)}>
        {selected && <CheckIcon width={'24px'} className={styles['check-icon']}/>}
        {hovered && !selected && <ViHoverIcon width={'24px'} className={styles['hover-icon']}/>}
        {!hovered && !selected && <div className={styles['unchecked-icon']}/>}
    </div>
}

export default CheckComponent;