import React, { useState, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import styles from "./Popover.module.scss";
import classNames from "classnames";

let closeActive = null;
let setActivePosition = null;
let activeButtonRef = null;
let activeRenderPosition = null;
export let activePopoverRef = null;

const calculatePosition = (ref) => {
    const rect = ref.current.getBoundingClientRect();
    let left = rect.left;
    if(activeRenderPosition === "center") {
        left = left + rect.width / 2;
    } else if (activeRenderPosition === "right") {
        left = left + rect.width;
    }
    const top = rect.top + rect.height;

    return {left, top};
};

const updatePosition = () => {
    if (!activeButtonRef || !activeButtonRef.current) return;
    setActivePosition(calculatePosition(activeButtonRef));
};

window.addEventListener("resize", updatePosition);
window.addEventListener("scroll", updatePosition, true);
const observer = new MutationObserver(updatePosition);
observer.observe(document, { attributes: true, childList: true, characterData: true, subtree: true });

export const closeActivePopover = () => {
    if(closeActive) {
        closeActive(false);
        closeActive = null;
        activeButtonRef = null;
        activePopoverRef = null;
        setActivePosition = null;
        activeRenderPosition = null;
    }
};

const closeListener = (e) => {
    if(activePopoverRef && activePopoverRef.current && !activePopoverRef.current.contains(e.target) && activeButtonRef && activeButtonRef.current && e.target !== activeButtonRef.current && closeActive) {
        closeActivePopover();
    }
};

document.body.addEventListener('click', closeListener);

const Context = React.createContext();
export const PopoverContext = ({Component = "div", children, ...props}) => {
    const ref = useRef(null);
    return <Context.Provider value={ref}>
        <Component ref={ref} {...props}>
            {children}
        </Component>
    </Context.Provider>
};

const RenderPopover = ({children}) => {
    const popoverContextRef = useContext(Context);

    return ReactDOM.createPortal(children, popoverContextRef?.current || document.body)
};

export default ({triggerOnClick, Trigger, TriggerButton, renderPosition = "center", triggerStyle, triggerClassName, popoverClassName, popoverStyle, children, arrowClassName, arrowStyle, contentClassName, contentStyle, Popover, disabled, ...rest}) => {
    const [isActive, setActive] = useState(false);
    const [isEntering, setIsEntering] = useState(false);
    const [position, setPosition] = useState({top: 0, left: 0});
    const buttonRef = useRef(null);
    const popoverRef = useRef(null);

    const registerAsActive = () => {
        activeRenderPosition = renderPosition;
        setPosition(calculatePosition(buttonRef));
        setActive(true);
        setIsEntering(true);
        setTimeout(() => {
            if(activePopoverRef === popoverRef) {
                setIsEntering(false);
            }
        }, 0);

        closeActive && closeActive(false);
        closeActive = setActive;
        setActivePosition = setPosition;
        activeButtonRef = buttonRef;
        activePopoverRef = popoverRef;
    };

    const triggerProps = {
        style: triggerStyle,
        className: classNames(styles['popover-trigger'], triggerClassName),
        disabled: disabled
    };

    const close = () => {
        setActive(false);
        closeActive = null;
        activeButtonRef = null;
        activePopoverRef = null;
        setActivePosition = null;
        activeRenderPosition = null;
    };

    if(disabled && isActive) {
        setTimeout(() => {
            close();
        });
    }

    if(triggerOnClick) {
        triggerProps.onClick = (event) => {
            if(isActive) {
                close();
            } else {
                registerAsActive();
            }
            event.preventDefault();
            event.stopPropagation();
        }
    } else {
        triggerProps.onMouseEnter = () => {
            registerAsActive();
        };

        triggerProps.onMouseLeave = () => {
            close();
        }
    }
    const style = {...popoverStyle, ...position, opacity: isEntering ? 0 : 1};
    return <>
        {!TriggerButton && <button type="button" ref={buttonRef} {...triggerProps}><Trigger isActive={isActive}/></button>}
        {TriggerButton && <TriggerButton buttonRef={buttonRef} {...triggerProps} isActive={isActive}/>}
        {isActive && <RenderPopover><div style={style} className={classNames(styles['popover'], popoverClassName)} ref={popoverRef}>
            <Popover
                position={position}
                arrowClassName={arrowClassName}
                arrowStyle={arrowStyle}
                contentClassName={contentClassName}
                contentStyle={contentStyle}
                close={close}
                {...rest}
            >
                {children}</Popover>
        </div></RenderPopover>}
    </>
};
