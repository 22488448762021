import React from "react";
import classNames from "classnames";
import styles from "./Checkbox.module.scss";

export default React.memo(({ form, name, value, style, onChange: _onChange, checked: _checked, disabled, className, children, showError: _showError, ...rest }) => {
    let onChange = _onChange;
    let checked = _checked;
    let showError = _showError;
    if(form && name) {
        checked = checked || form.formState[name];
        onChange = onChange || form.handleFormChange;
        if(!_showError) {
            showError = (form.submissionAttempted || (form.dirty[name])) && form.formErrors[name]
        }
    }

    return <label className={classNames(styles["base"], className, {[styles["disabled"]]: disabled, [styles["active"]]: checked, [styles["error"]]: showError})} style={style}>
        <input checked={checked} onChange={onChange} readOnly={!onChange} type="checkbox" name={name} value={value === undefined ? name : value} disabled={disabled} {...rest}/>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
            <path className={styles['on-path']} d="M20.222-681H7.778A1.777 1.777 0 0 0 6-679.222v12.444A1.777 1.777 0 0 0 7.778-665h12.444A1.777 1.777 0 0 0 22-666.778v-12.444A1.777 1.777 0 0 0 20.222-681zm-8 12.444L7.778-673l1.253-1.253 3.191 3.182 6.747-6.747 1.253 1.262z" transform="translate(-6 681)"/>
            <path className={styles['off-path']} fill="#c8c8c8" d="M7.778-680c-.43 0-.778.349-.778.778v12.444c0 .43.349.778.778.778h12.444c.43 0 .778-.349.778-.778v-12.444c0-.43-.349-.778-.778-.778H7.778m0-1h12.444c.987 0 1.778.8 1.778 1.778v12.444c0 .978-.791 1.778-1.778 1.778H7.778C6.79-665 6-665.8 6-666.778v-12.444C6-680.2 6.791-681 7.778-681z" transform="translate(-6 681)"/>
        </svg>
        {children}
    </label>
});