import Modal, {Header, ActionFooter, Content} from "./../Modal";
import React, {useState} from "react";
import styles from "./ProviderTagsModal.module.scss";
import {Heading} from "../Typography";
import Button from "./Button";
import {useForm, Checkbox, FormFieldInputNew} from "./../Forms";
import _ from "lodash";
import {set} from "lodash/fp";
import * as yup from "yup";


export const TagSelectionGridHeader = ({keyName, valueName}) => {
    return <div className={styles['selection-tags-grid-header']}>
        <Heading level={4}>{keyName}</Heading>
        <Heading level={4}>{valueName}</Heading>
    </div>
}

export const TagSelectionGrid = ({form, keyName="key", valueName="value"}) => {
    const {formState, handleFormChange} = form;
    const {tagSelection} = formState;
    return <div className={styles['selection-tags-grid']}>
        {_.map(tagSelection, (tag, index) => {
            return <React.Fragment key={index}>
                <div>
                    <Checkbox checked={tag.checked} name='checked' form={form} onChange={() => {
                        const checkedState = tagSelection[index].checked;
                        handleFormChange(set(`[${index}].checked`, !checkedState)(tagSelection), {name: "tagSelection"})
                    }}/>
                </div>
                <FormFieldInputNew
                    value={tag[keyName]}
                    name={keyName}
                    disabled={true}
                />
                <FormFieldInputNew
                    value={tag[valueName]}
                    disabled={true}
                    name={valueName}
                />
            </React.Fragment>
        })}
    </div>
}

const validation =  yup.object().shape({
        tagSelection: yup
            .mixed()
            .test({
                test: tags => !!_.find(tags, {checked: true}),
                message: "At least one must be selected"
            })
    });

const TagSelectionModal = React.memo(({
                               keyName = "Key",
                               valueName = 'Value',
                               closeTrigger,
                               onSave,
                               tags,
                               SubLabel,
                               fixedHeight
                           }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const form = useForm({tagSelection: tags}, validation);
    const {handleFormSubmit, formErrors, submissionAttempted} = form;
    return <Modal className={styles.modal} style={fixedHeight ? {maxHeight: fixedHeight} : undefined}>
        <Header>View {keyName}</Header>
        <Content>
            {SubLabel && <div className={styles['sublabel-row']}><SubLabel/></div>}
            <TagSelectionGridHeader keyName={keyName} valueName={valueName}/>
            <TagSelectionGrid form={form}/>
        </Content>
        <ActionFooter error={submissionAttempted ? formErrors.tagSelection : null}>
            <Button isSubmitting={isSubmitting} color="primary" onClick={handleFormSubmit(async () => {
                setIsSubmitting(true);
                const returnValue = onSave(form.formState.tagSelection) || (Promise.resolve());
                try {
                    await returnValue;
                    closeTrigger();
                } catch (e) {
                    setIsSubmitting(false);
                }
            })}>Save</Button>
            <Button color="secondary" onClick={closeTrigger}>Cancel</Button>
        </ActionFooter>
    </Modal>
})


export default TagSelectionModal;