import React, {useEffect, useMemo} from 'react';
import {Button} from 'ui-components-rollup/dist/Widgets';
import {useDispatch, useSelector} from 'react-redux';
import {CSSTransition} from 'react-transition-group';
import {SlidingManagementWidgetHeader} from 'ui-components-rollup/dist/Tabs';
import {Span, Text} from 'ui-components-rollup';
import styles from './SlidingManagementWidget.module.scss';
import accountStyle from './accountDetails.module.scss';
import {ReactComponent as CloseIcon} from 'ui-components-rollup/src/images/monochrome-icons/ic-close.svg';


export default React.memo(({handleTabsClick}) => {

    const slideClassNames = {
        enter: styles['slide-enter'],
        enterActive: styles['slide-enter-active'],
        exit: styles['slide-exit'],
        exitActive: styles['slide-exit-active']
    };

    const accountPath = 'http://cloud.netapp.com/user_metadata';
    const dispatch = useDispatch();
    const isOpen = useSelector(state => state.style.accountTab);
    const organization = useSelector(state => state.organizations);
    const pageStyle = useSelector(state => state.style.theme);
    const {email, name, company, phone} = useSelector(state => state.auth.data[accountPath]);


    const logoutFunc = () => {
        setTimeout(() => {
            dispatch({type: 'AUTH:LOGIN'});
        }, 1000);
        dispatch({type: 'AUTH:LOGOUT'});

    };
    const setDefaultOrg = () => {
        if (organization.orgs || organization.selectedOrg) {
            return organization.orgs.find(id => id.orgId === organization.selectedOrg).orgName;
        } else return 'N/A';
    };
    const orgName = useMemo(() => {
        return setDefaultOrg();
    }, [organization]);

    const accountData = [{title: 'Organization', data: orgName}, {title: 'Email', data: email}, {
        title: 'Company',
        data: company
    }, {title: 'Phone', data: phone}];

    const setTheme = (theme) => {
        dispatch({type: 'changeTheme', payload: theme});
    };

    useEffect(() => {
        if (isOpen) document.body.classList.add(styles['management-widget-open']);
    }, [isOpen]);

    return <>
        <CSSTransition in={isOpen} timeout={600} unmountOnExit classNames={slideClassNames}>
            <div className={styles.base}>
                <SlidingManagementWidgetHeader>
                    <Span onClick={() => handleTabsClick()} className={accountStyle['exit-button']}>
                        <CloseIcon className={accountStyle['svg-style']}/>
                    </Span>
                    <h3>{name}</h3>
                    <Button className={accountStyle['button']} onClick={logoutFunc}>Logout</Button>
                </SlidingManagementWidgetHeader>
                <div className={accountStyle['slider-style']}>
                    {accountData.map((tab) => {
                        return <>
                            <div className={accountStyle['container']}>
                                <Text bold className={accountStyle['text-style']}>{tab.data}</Text>
                                <Text style={{color: '#a09f9f'}}>{tab.title}</Text>
                            </div>
                        </>;
                    })
                    }
                    <div className={accountStyle['container']}>
                        <div className={accountStyle['button-container']}>
                            <Button disabled={true} color={pageStyle === 'dark' ? 'secondary' : ''}
                                    onClick={() => setTheme('light')} className={accountStyle['button']}>Light</Button>
                            <Button disabled={true} color={pageStyle === 'light' ? 'secondary' : ''}
                                    onClick={() => setTheme('dark')} className={accountStyle['button']}>Dark</Button>
                        </div>
                        <Text style={{color: '#a09f9f'}}>Background color</Text>
                    </div>
                </div>
            </div>
        </CSSTransition>
        {isOpen && <div className={styles['mask']}/>}
    </>;
});
