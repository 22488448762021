import { Card } from "../Cards";
import styles from "./CardRadioGroup.module.scss";
import React from "react";
import classNames from "classnames";

const CardRadioGroup = ({label, labelStyle, children, className, style}) => {
    return <Card className={classNames(styles.base, className)} style={style}>
        {label && <div style={labelStyle} className={styles.label}>{label}:</div>}
        <div className={styles.radios}>{children}</div>
    </Card>
};

export default CardRadioGroup;
