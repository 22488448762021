import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { CSSTransition } from "react-transition-group";
import styles from "./SlidingManagementWidget.module.scss";
import navStyle from './navSlider.module.scss'
import { Text } from 'ui-components-rollup';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CloseIcon } from "ui-components-rollup/src/images/monochrome-icons/ic-close.svg"
import { ReactComponent as MonitorIcon } from "ui-components-rollup/src/images/2-colors-icons/ic-user-card.svg"
import { ReactComponent as CloudIcon } from "ui-components-rollup/src/images/2-colors-icons/ic-sales-enablement.svg"
import { ReactComponent as KeeAliveIcon } from "ui-components-rollup/src/images/2-colors-icons/ic-efficiency.svg"

export default React.memo(({ handleTabsClick }) => {

    const slideClassNames = {
        enter: styles['slide-enter'],
        enterActive: styles['slide-enter-active'],
        exit: styles['slide-exit'],
        exitActive: styles['slide-exit-active']
    };
    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()
    const isOpen = useSelector(state => state.style.navBarTab)
    const handleRoute = (route, path) => {
        dispatch({ type: 'changeTab', payload: route })
        history.push(path)
    }

    useEffect(() => {
        handleTabsClick()
    }, [location])

    useEffect(() => {
        if (isOpen) document.body.classList.add(styles['management-widget-open']);
    }, [isOpen])

    return <>
        <CSSTransition in={isOpen} timeout={600} unmountOnExit classNames={slideClassNames}>
            <div className={styles.base}>
                <div className={navStyle['slider-style']}>
                    <div className={navStyle['nav-header']}>
                        <h3>Categories</h3>
                        <CloseIcon onClick={() => handleTabsClick()} className={navStyle['exit-button-svg-style']} />
                    </div>
                    <div className={navStyle['select-style']} onClick={() => handleRoute('cost', '/cost-chart')}>
                        <CloudIcon className={navStyle['svg-style']} />
                        <Text level={100} style={{ marginTop: 8 }} center bold>Cost</Text>
                    </div>
                    <div className={navStyle['select-style']} onClick={() => handleRoute('security', '/guard-duty')}>
                        <MonitorIcon className={navStyle['svg-style']} />
                        <Text level={100} style={{ marginTop: 8 }} center bold>Security</Text>
                    </div>
                    <div className={navStyle['select-style']} onClick={() => handleRoute('monitor', '/ec2-instances-table')}>
                        <KeeAliveIcon className={navStyle['svg-style']} />
                        <Text level={100} style={{ marginTop: 8 }} center bold>Monitor</Text>
                    </div>
                </div>
            </div>
        </CSSTransition>
        {isOpen && <div className={styles['mask']} />}
    </>
});