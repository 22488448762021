import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heading, Text, ComponentLoader } from 'ui-components-rollup';
import _ from 'lodash';
import mapStyles from './CostMap.module.scss';
import styles from './pageStyle.module.scss';

const CostMap = () => {


    const dispatch = useDispatch();
    let [errorText, setErrorText] = useState(null);
    const mapData = useSelector(state => state.costMap.mapData);
    const isMapLoaded = useSelector(state => state.costMap.isMapLoaded);
    const selectedOrg = useSelector(state => state.organizations.selectedOrg);
    const error = useSelector(state => state.costMap.error);

    useEffect(() => {
        dispatch({ type: 'MAP:LOAD-DATA' });
    }, []);

    useEffect(() => {
        if (error) setErrorText(errorText = `Failed to get table:  ${_.isString(error) ? error : ''} `);
        if (!selectedOrg) setErrorText(errorText = 'Sorry you don\'t have accounts to monitor');
    }, [error, selectedOrg]);

    return <>
        <div className={styles['rt-container']}>
            <div className={styles['header']}>
                <Heading className={styles['header-title']}>Costs map</Heading>
                <p className={styles['header-line']} />
                {errorText && <Text bold>{errorText}</Text>}
                {isMapLoaded ? <img className={mapStyles['map']} src={mapData} />
                    : <ComponentLoader className={mapStyles['loader-svg']} />}
            </div>
        </div>
    </>

};
export default CostMap;