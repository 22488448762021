import React from "react";
import PopoverMechanism from "./PopoverMechanism"
import classNames from "classnames";
import styles from "./Popover.module.scss";
import {ReactComponent as TooltipIcon} from "./ic_tooltip_grey.svg";

const Popover = ({white, children, arrowClassName, arrowStyle, contentClassName, contentStyle, placement}) => {
    const variant = white ? "white" : "default";
    return <>
        <div className={classNames(styles[`popover-arrow-${variant}`], arrowClassName, styles[`popover-arrow-${placement}`])} style={arrowStyle}/>
        <div className={classNames(styles[`popover-content-${variant}`], contentClassName)} style={contentStyle}>
            {children}
        </div>
    </>
};

/**
 *
 triggerOnClick,
 triggerClassName,
 popoverClassName, popoverStyle, children,
 white,
 arrowClassName,
 arrowStyle,
 contentClassName,
 contentStyle
 */
export default React.memo(({white, popoverClassName, Trigger: _Trigger, right, placement: horizontalPlacement = "right", verticalPlacement = "down", ...props}) => {
    const variant = white ? 'white' : 'default';

    const placement = `${verticalPlacement}-${horizontalPlacement}`

    const Trigger = _Trigger ? _Trigger : ({isActive}) => {
        return <TooltipIcon className={classNames(styles[`info-button-${variant}`], {[styles[`info-button-active-${variant}`]]: isActive})}/>;
    };
    return <PopoverMechanism popoverClassName={classNames(styles[`${placement}-popover`], popoverClassName)} placement={placement} white={white} {...props} Popover={Popover} Trigger={Trigger}/>
});
