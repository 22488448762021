import React from "react";
import SVG from "react-inlinesvg";
import styles from "./TabHeader.module.scss"

export default ({logo, Icon, label, children}) => {
    return <header className={styles.base}>
        <div className={styles["service"]}>
            {logo && <SVG src={logo}/>}
            {Icon && <Icon/>}
            <span>{label}</span>
        </div>
        {children}
    </header>
};

