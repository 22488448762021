import {useEffect, useMemo, useState} from 'react';
import LinksTab from './LinksTab';
import {useDispatch, useSelector} from 'react-redux';
import styles from './MainHeader.module.scss';
import classNames from 'classnames';
import Modal, {Header, Content, Footer} from 'ui-components-rollup/dist/Modal';
import {ReactComponent as AccountIcon} from 'ui-components-rollup/src/images/monochrome-icons/ic-user.svg';
import {ReactComponent as RefreshIcon} from 'ui-components-rollup/src/images/monochrome-icons/ic-backup.svg';
import {ReactComponent as CloudIcon} from 'ui-components-rollup/src/images/monochrome-icons/ic-cloud.svg';
import {ReactComponent as ArrowIcon} from 'ui-components-rollup/src/images/monochrome-icons/ic-arrow-no-circle.svg';
import {ComponentLoader} from 'ui-components-rollup';
import {FormFieldSelect, useForm} from 'ui-components-rollup/dist/Forms';

const MainHeader = () => {

    const accountPath = 'http://cloud.netapp.com/user_metadata';
    let [cloudSelected, setCloudSelected] = useState(false);
    const refreshData = useSelector(state => state.organizations.refreshData);
    const organization = useSelector(state => state.organizations);
    const {name} = useSelector(state => state.auth.data[accountPath]);
    const dispatch = useDispatch();
    const setDefaultOrg = () => {
        if (organization) return organization.orgs.find(id => id.orgId === organization.selectedOrg).orgName;
        else return 'N/A';
    };

    const orgName = useMemo(() => {
        return setDefaultOrg();
    }, [organization]);

    const form = useForm({changeOrg: ''});
    const onOnEnvSelection = (e) => {
        form.handleFormChange(e, {name: 'changeOrg'});
    };

    const onSubmit = () => {
        const currentOrg = form.formState.changeOrg.value;
        const currentId = organization.orgs.find(item => item.orgName === currentOrg).orgId;
        window.sessionStorage.setItem('org', currentId);
        dispatch({type: 'ORGANIZATIONS:CHANGEORG', orgId: currentId});
        window.location.reload();
    };

    const setOptions = () => {
        let result = [];
        if (organization.orgs.length > 1) {
            result = organization.orgs.map((org) => {
                return {label: org.orgName, value: org.orgName};
            });
        }
        return result;
    };

    const options = useMemo(() => {
        return setOptions();
    }, [organization]);

    const onClickAccount = () => {
        dispatch({type: 'accountTab:open'});
    };
    const onCLickRefreshData = () => {
        dispatch({type: 'ORGANIZATIONS:REFRESH'});
    };
    const onClickExpand = () => {
        dispatch({type: 'navBar:open'});
    };


    useEffect(() => {
        dispatch({type: 'ORGANIZATIONS:GETORGS'});
    }, [dispatch]);

    return <>
        <div className={styles['root-header-style']}>
            <LinksTab/>
            <AccountIcon onClick={() => onClickAccount()}
                         className={classNames(styles['svg-style'], styles['svg-position'])}/>
            {!refreshData ?
                <RefreshIcon onClick={() => onCLickRefreshData()}
                             className={classNames(styles['svg-style'], styles['svg-position'])}/>
                : <ComponentLoader className={classNames(styles['svg-style'], styles['svg-position'])}/>}
            <CloudIcon onClick={() => setCloudSelected(!cloudSelected)}
                       className={classNames(styles['svg-style'], styles['svg-position'])}>
            </CloudIcon>
            {cloudSelected && <>
                <Modal>
                    <Header>
                        Cloud Environment: {orgName}
                    </Header>
                    <Content style={{height: '250px'}}>
                        <FormFieldSelect
                            label="change organizations"
                            name="changeOrg"
                            isSearchable={true}
                            form={form}
                            options={options}
                            onChange={onOnEnvSelection}
                        />
                    </Content>
                    <Footer style={{alignSelf: 'center'}}>
                        <button onClick={onSubmit} style={{width: 200}}>Save</button>
                        <button onClick={() => setCloudSelected(false)} style={{width: 200}}>Cancel</button>
                    </Footer>
                </Modal>
            </>}
            <span className={styles['border']}/>
            <span className={styles['account-name']}>{name}</span>
            <ArrowIcon onClick={() => onClickExpand()}
                       className={classNames(styles['svg-style'], styles['arrow-icon'])}/>
        </div>
    </>;
};
export default MainHeader;

