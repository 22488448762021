import React, { useEffect, useRef, useState} from "react"
import _ from 'lodash';
import styles from "./SearchWidget.module.scss"
import classNames from "classnames";
import { ReactComponent as SearchIcon } from "../../images/monochrome-icons/ic-search.svg";
import { ReactComponent as CloseIcon } from "../../images/monochrome-icons/ic-close.svg";

export default ({setFilter, alwaysOpen, className, placeholder, style, widgetStyle, widgetClassName}) => {
    const input = useRef(null);
    const [isOpen, setIsOpen] = useState(!!alwaysOpen);
    const [value, setValue] = useState("");
    const [isFocussed, setIsFocussed] = useState(false);
    const throttledSetFilter = _.debounce((value) => setFilter(value), 500);

    const open = () => {
        setIsOpen(true);
        setTimeout(() => {
            input.current.focus();
        });
    };

    const close = () => {
        if(!alwaysOpen) {
            setIsOpen(false);
        }
        setValue("");
        throttledSetFilter("");
    };

    const handleBlur = () => {
        setIsFocussed(false);
        if (!value) close();
    };

    const handleChange = (event) => {
        const currValue = event.target.value;
        throttledSetFilter(currValue);
        setValue(currValue);
    };

    const handleKeyDown = (event) => {
        const currValue = event.target.value;
        if (event.key === 'Enter' || event.key === 'Backspace') throttledSetFilter(currValue);
        setValue(currValue);
    };

    return <div className={classNames(styles.container, className)} style={style}>
        <div className={classNames(styles.base, widgetClassName, {[styles['open']]: isOpen, [styles['focussed']]: isFocussed})} style={widgetStyle}>
            <button type="button" className={classNames(styles['open-button'])} onClick={alwaysOpen ? undefined : open}><SearchIcon/></button>
            <input className={classNames(styles['input'])} placeholder={placeholder || 'Search'}
                   onBlur={() => handleBlur()}
                   onFocus={() => setIsFocussed(true)}
                   ref={input}
                   value={value}
                   onChange={handleChange}
                   onKeyDown={handleKeyDown}/>
            <button type="button" className={styles['close-button']} onClick={close}><CloseIcon/></button>
        </div>
    </div>;
}
