import React from "react";
import _ from "lodash";
import classNames from "classnames";
import styles from "./Table.module.scss";
import { extractValue } from "./useTable";
import { UnmountClosed } from "react-collapse";
import  DefaultCellRenderer  from "./DefaultCellRenderer";

export default React.memo(({     columns,
                                           row,
                                           rowId,
                                           rowClassName,
                                           cellClassName,
                                           widths,
                                           totalWidth,
                                           isRowSelectingEnabled,
                                           isMultiSelect,
                                           isRowSelected,
                                           toggleRowSelection,
                                           rowState,
                                           updateRowState,
                                           onRowClick,
                                           onRowDoubleClick,
                                           lastColumnSticky,
                                           lastColumnStickyStyleRef,
                                           stickColumnIndex,
                                           stickyColumnStyleRef,
                                           SubRowRenderer,
                                           isRowDisabled,
                                           disabledTitle
                                       }) => {
    const updateRow = updateRowState && updateRowState(rowId);
    const selectRow = isRowSelectingEnabled && toggleRowSelection(rowId);
    const onClick = onRowClick && (() => onRowClick(row));
    const onDoubleClick = onRowDoubleClick && (() => onRowDoubleClick(row));
    const isSelected = rowState?.isSelected;
    const isExpanded = rowState?.isExpanded;
    const disabled = _.isFunction(isRowDisabled) ? isRowDisabled(row) : false;
    return <>
        <div title={disabled ? disabledTitle : undefined} className={classNames(styles['row'], rowClassName, { [styles['selected']]: isSelected, [styles['expanded']]: isExpanded, [styles['clickable']]: !!onClick, [styles['disabled-row']]: disabled })} onClick={onClick} onDoubleClick={onDoubleClick} style={{ width: totalWidth }}>
            {columns.map((column, index) => {
                const isSelectionColumn = isRowSelectingEnabled && index === 0;
                const stickCustomColumn = index <= stickColumnIndex;
                const stickLastColumn = lastColumnSticky && index === columns.length - 1;
                const className = classNames(styles['cell'], column.cellClass, cellClassName, { [styles['cell-selecting']]: isSelectionColumn, [lastColumnStickyStyleRef?.current.dataset.styleid]: stickLastColumn, [styles['disabled-cell']]: disabled, [stickyColumnStyleRef?.current.dataset.styleid]: stickCustomColumn });
                const CellRenderer = column.Renderer || DefaultCellRenderer;
                const value = extractValue(row, column.accessor);
                const additionalStyles = column.cellStyle ? column.cellStyle({ value, row, rowState, isRowSelected }) : undefined;
                const style = {
                    flex: column.fixedWidth ? `0 0 ${widths[index]}px` : `1 0 ${widths[index]}px`,
                    ...additionalStyles
                };

                return <div key={column.id} className={className} style={style}>
                    <CellRenderer row={row} value={value} column={column}
                                  isSelectionColumn={isSelectionColumn} isMultiSelect={isMultiSelect}
                                  isRowSelected={isRowSelected} updateRow={updateRow} selectRow={selectRow}
                                  rowState={rowState} disabled={disabled}/>
                </div>
            })}
        </div>
        {SubRowRenderer && <UnmountClosed isOpened={isExpanded} theme={{
            collapse: styles['sub-row-transition-parent'],
            content: classNames(styles['sub-row'], stickyColumnStyleRef.current.dataset.styleid)
        }}>
            <SubRowRenderer row={row} rowState={rowState} updateRow={updateRow} />
        </UnmountClosed>}
    </>
});
