import React, { useEffect, useState } from 'react';
import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm//languages/prism/json';
import js from 'react-syntax-highlighter/dist/esm//languages/prism/javascript';
import jsx from 'react-syntax-highlighter/dist/esm//languages/prism/jsx';
import prismStyles from 'react-syntax-highlighter/dist/esm/styles/prism/prism'
import { darcula as darkStyle } from 'react-syntax-highlighter/dist/esm/styles/prism';
SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('javascript', js);
SyntaxHighlighter.registerLanguage('jsx', jsx);

export default React.memo(({children: _children, dark, ...props}) => {
    const [children, setChildren] = useState(null);

    useEffect(() => {
        const children = _.isObject(_children) ? JSON.stringify(_children, null, 4) : _children;
        setChildren(children);
    }, [_children]);

    return children && <SyntaxHighlighter style={dark ? darkStyle : prismStyles} {...props}>
        {children}
    </SyntaxHighlighter>
});
