import React from "react";
import _ from "lodash";
import { ReactComponent as Chevron } from "./../../images/monochrome-icons/ic-chevron.svg";
import styles from "./Breadcrumbs.module.scss"
import classNames from "classnames";

export default ({children, className}) => {
    return <div className={classNames(styles.base, className)}>
        {!_.isArray(children) && children}

        {_.isArray(children) && children.map((child, index) => {
            const isLast = index + 1 === children.length;
            return <React.Fragment key={index}>
                    {child}
                    {!isLast && <Chevron className={styles.chevron}/>}
                </React.Fragment>
        })}
    </div>
};
