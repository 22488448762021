import React, { useEffect, useCallback, useMemo, useState } from 'react';
import Table, { TableCounter, TableWidgets, useTable } from 'ui-components-rollup/dist/Tables';
import { SearchWidget } from 'ui-components-rollup/dist/Widgets';
import { Heading, Text } from 'ui-components-rollup';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styles from './pageStyle.module.scss';
import columnStyle from './serviceStatus.module.scss';
import classNames from 'classnames';
import { dateEditor } from '../utils/utils'

const AzureAdvisorTableNew = () => {

    let [errorText, setErrorText] = useState(null);
    const dispatch = useDispatch();
    const isTableLoaded = useSelector(state => state.azureAdvisorTable.isTableLoaded);
    const tableData = useSelector(state => state.azureAdvisorTable.tableData);
    const error = useSelector(state => state.azureAdvisorTable.error);
    const selectedOrg = useSelector(state => state.organizations.selectedOrg);
    const Columns = [
        {
            header: 'Subscription',
            accessor: 'Subscription',
            id: 'Subscription',
            width: 150,
            sort: {
                enabled: false
            },
            filter: {
                enabled: true,
                RenderFilter: 'dropdown',
            }
        },
        {
            header: 'Category',
            accessor: 'Category',
            id: 'Category',
            width: 100,
            sort: {
                enabled: false
            },
            filter: {
                enabled: true,
                RenderFilter: 'dropdown',
            }
        },
        {
            header: 'Updated At',
            accessor: 'LastUpdated',
            id: 'LastUpdated',
            sort: {
                enabled: true
            },
            width: 150
        }, {
            header: 'Problem',
            accessor: 'Problem',
            id: 'Problem',
            sort: {
                enabled: true
            },
            width: 500
        },
        {
            header: 'Impact',
            accessor: 'Impact',
            id: 'Impact',
            sort: {
                enabled: false
            },
            filter: {
                enabled: true,
                RenderFilter: 'dropdown',
            },
            width: 100,
            Renderer: ({ value }) => {
                return <div className={columnStyle["status"]}>
                    <span className={classNames(columnStyle["circle"], value === "High" ? columnStyle["high"] : value === "Medium" ? columnStyle["medium"] : columnStyle["regular"])} />
                </div>
            }
        },
    ];
    let fixedData = useMemo(() => {
        return dateEditor(tableData.data, 'LastUpdated')
    }, [tableData])

    const {
        sortState,
        toggleSort,
        filterState,
        updateFilterState,
        rows,
        organizedRows,
        rowState,
        updateRowState,
        updateTextFilter,
        toggleRowSelection,
        columns
    } = useTable({
        columns: Columns,
        rows: fixedData,
        isSorting: true,
        isRowSelecting: true
    });

    const SubRowRenderer = ({ row }) => {

        const addSpacesToImapctVal = (row) => {
            return row.ImpactedValue.map((item) => {
                return item.concat('', ' ')
            })
        }

        return <>
            <div style={{ margin: '20px 43px 20px 37px', display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text bold style={{ margin: '0 10px 0 0 ' }}>Impact: </Text>
                    <Text>  {row.Impact}</Text>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text bold style={{ margin: '0 10px 0 0 ' }}>Solution: </Text>
                    <Text>  {row.Solution}</Text>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Text bold style={{ margin: '0 10px 0 0 ' }}>Impacted value: </Text>
                    <Text>  {[...addSpacesToImapctVal(row)]}</Text>
                </div>
            </div>
        </>
    };
    const onRowClick = useCallback((row) => {
        updateRowState(row.id)(rowState => ({ isExpanded: !(rowState && rowState.isExpanded) }));
    }, [updateRowState]);


    const filterOptions = useMemo(() => {
        return {
            Subscription: _.uniqBy(rows.map((row) => {
                return { label: row.Subscription || 'N/A', value: row.Subscription };
            }), 'value'),
            Category: _.uniqBy(rows.map((row) => {
                return { label: row.Category || 'N/A', value: row.Category };
            }), 'value'),
            Impact: _.uniqBy(rows.map((row) => {
                return { label: row.Impact || 'N/A', value: row.Impact };
            }), 'value'),

        };
    }, [rows]);


    useEffect(() => {
        dispatch({ type: 'AZUREADVISOR:LOAD-DATA' });
    }, [dispatch]);

    useEffect(() => {
        if (error) setErrorText(errorText = `Failed to get table:  ${_.isString(error) ? error : ''} `);
        if (!selectedOrg) setErrorText(errorText = 'Sorry you don\'t have accounts to monitor');
    }, [error, selectedOrg]);


    return <>
        <div className={styles['rt-container']}>
            <div className={styles['header']}>
                <Heading className={styles['header-title']}>Azure advisor</Heading>
                <p className={styles['header-line']} />
            </div>
            <div className={styles['table-widget']}>
                <TableCounter
                    filteredCount={organizedRows.length}
                    className={styles['table-counter']}
                    totalCount={rows.length}
                    pluralLabel="Advices"
                    singularLabel="Advices" />
                <TableWidgets>
                    <SearchWidget setFilter={updateTextFilter} />
                </TableWidgets>
            </div>
            {errorText && <Text center bold>{errorText}</Text>}
            <Table
                className={styles['table-animation']}
                isSticky
                headerContainerStyle={{ position: 'sticky', zIndex: 49, top: '15vh' }}
                columns={columns}
                rows={errorText ? [] : organizedRows}
                isLoading={!isTableLoaded}
                rowState={rowState}
                updateRowState={updateRowState}
                toggleRowSelection={toggleRowSelection}
                filterOptions={filterOptions}
                filterState={filterState}
                updateFilterState={updateFilterState}
                sortState={sortState}
                SubRowRenderer={SubRowRenderer}
                onRowClick={onRowClick}
                toggleSort={toggleSort}
            />
        </div>
    </>
}
export default AzureAdvisorTableNew