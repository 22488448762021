import React, { useEffect, useRef } from 'react'
import {activePopoverRef} from "../Popover/PopoverMechanism";

export default ({listener, ignoreParentClick, capture, ignoreTargetDataAction}) => {
    const ref = useRef(null);

    const _listener = React.useCallback((e) => {
        if(ignoreParentClick) {
            if((ref.current && ref.current.parentNode && ref.current.parentNode.contains(e.target))) {
                return;
            } else if (activePopoverRef && activePopoverRef.current && activePopoverRef.current.contains(e.target)) {
                return;
            }
        }

        if(ignoreTargetDataAction && e.target.getAttribute("data-action") === ignoreTargetDataAction) {
            return;
        }

        listener(e);

    }, [listener, ignoreParentClick]);

    useEffect(() => {
        let added = false;
        setTimeout(() =>{
            document.body.addEventListener('click', _listener, capture);
            added = true;
        });
        return () => {
            if(added) {
                document.body.removeEventListener('click', _listener, capture);
            } else {
                setTimeout(() => {
                    document.body.removeEventListener('click', _listener, capture);
                });
            }
        };
    }, [_listener]);

    return <div ref={ref} style={{display: "none"}}/>
}
