import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Heading } from 'ui-components-rollup';
import _ from 'lodash';
import styles from './pageStyle.module.scss';
import { Bar } from 'react-chartjs-2';
import { ComponentLoader } from 'ui-components-rollup';

const CostChartNew = () => {

    let [errorText, setErrorText] = useState(null);
    const chartData = useSelector(state => state.costChart.chartData);
    const error = useSelector(state => state.costChart.error);
    const selectedOrg = useSelector(state => state.organizations.selectedOrg);
    const dispatch = useDispatch();


    const chartMaker = () => {
        chartData.options.hover = {
            "animationDuration": 0
        };
        chartData.options.animation = {
            "duration": 1,
            "onComplete": function () {
                const chartInstance = this.chart,
                    ctx = chartInstance.ctx;

                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';
                let sum = [];
                let xPoint = [];
                let yPoint = [];
                this.data.datasets.forEach(function (dataset, i) {
                    const meta = chartInstance.controller.getDatasetMeta(i);
                    meta.data.forEach(function (bar, index) {
                        if (!sum[index])
                            sum[index] = 0;
                        sum[index] += +dataset.data[index];
                        xPoint[index] = bar._model.x;
                        yPoint[index] = bar._model.y - 5
                    });
                });
                sum.forEach(function (sum, index) {
                    ctx.fillText(sum.toFixed(2), xPoint[index], yPoint[index]);
                });
            }
        };

    }
    useEffect(() => {
        dispatch({ type: 'CHART:LOAD-DATA' })
    }, [])

    useEffect(() => {
        if (chartData != undefined) chartMaker()
    }, [chartData])

    useEffect(() => {
        if (error) setErrorText(errorText = `Failed to get table:  ${_.isString(error) ? error : ''} `);
        if (!selectedOrg) setErrorText(errorText = 'Sorry you don\'t have accounts to monitor');
    }, [error, selectedOrg]);

    return <>
        <div className={styles['rt-container']}>
            <div className={styles['header']}>
                <Heading className={styles['header-title']}>Costs chart</Heading>
                <p className={styles['header-line']} />
                <div style={{ width: '100%', height: '100%' }}>
                    {chartData ? <Bar id="myChart" height={120} data={chartData.data} options={chartData.options} /> : <ComponentLoader className={styles['loader-svg']} />}
                </div>
            </div>
        </div>

    </>
}

export default CostChartNew