import React, { useEffect, useRef, useLayoutEffect, useState, useMemo } from "react";
import _ from "lodash";
import classNames from "classnames";
import styles from "./Table.module.scss";
import scrollFixStyles from "./ScrollFix.module.scss";
import PropTypes from 'prop-types';
import { ReactComponent as NoDataIcon } from "./../../images/monochrome-icons/ic-document.svg";
import { MenuPopover } from "./../Popover";
import { CSSTransition } from "react-transition-group";
import { fadeClassNames } from "../../animations"
import ColumnsSelector from "./ColumnsSelector"
import { InView } from 'react-intersection-observer';
import tableStyles from "./Table.module.scss";
import BlankRow from "./BlankRow"
import DefaultRowRenderer from "./DefaultRowRenderer"
import RenderHeaderCell from "./RenderHeaderCell"
import RenderRows from "./RenderRows"
import ToggleColumnSelectorButton from "./ToggleColumnSelectorButton"
import selectingColumn from "./selectingColumn";
import Measure from "react-measure";

const emptyFilterState = { columns: {}, count: 0 };
const emptyFilterOptions = {};
const emptyRows = [];

/**
 * rowMenu can be function to generate menu array or {menu, isDisabled}
 */
const menuColumn = (rowMenu) => {
    const rowMenuFunc = _.isFunction(rowMenu) ? rowMenu : rowMenu.menu;
    const isDisabledFunc = rowMenu.isDisabled;

    return {
        id: "menu",
        width: 79,
        fixedWidth: true,
        header: "",
        accessor: "",
        cellClass: tableStyles['menu-cell'],
        sort: {
            enabled: false
        },
        filter: {
            enabled: false
        },
        Renderer: ({ row }) => {
            return <>
                <MenuPopover popoverClassName={tableStyles['table-menu']} arrowClassName={tableStyles['table-menu-arrow']} disabled={isDisabledFunc && isDisabledFunc(row)} Trigger="oval" menu={() => rowMenuFunc(row)}/>
            </>
        }
    }
}


const Table = React.memo(({
                              rows: _rows,
                              columns: _columns,
                              table,
                              fixedHeight,
                              sortState: _sortState,
                              toggleSort: _toggleSort,
                              filterOptions: _filterOptions,
                              filterState: _filterState,
                              updateFilterState: _updateFilterState,
                              selectionState: _selectionState,
                              toggleRowSelection: _toggleRowSelection,
                              rowState: _rowState,
                              updateRowState: _updateRowState,
                              selectAllRows: _selectAllRows,
                              className,
                              cellClassName,
                              rowClassName,
                              rowsContainerClassName,
                              headerContainerClassName,
                              headerCellClassName,
                              headerHandleClassName,
                              headerRowClassName,
                              headerContainerStyle,
                              isSticky,
                              autoSticky,
                              lastColumnSticky,
                              stickColumnIndex = 0,
                              isLoading,
                              RenderRow = DefaultRowRenderer,
                              rowMenu,
                              onRowClick,
                              onRowDoubleClick,
                              columnState: _columnState,
                              updateColumnState: _updateColumnState,
                              isTogglingColumns,
                              restoreColumnStateDefaults: _restoreColumnStateDefaults,
                              originalColumns,
                              toggleColumnSelectorClassName,
                              toggleColumnSelectorStyle,
                              columnSelectorClassName,
                              SubRowRenderer,
                              noDataLabel = "No Table Data",
                              isRowDisabled,
                              disabledTitle
                          }) => {
    const headersContainerRef = useRef(null);
    const tableRef = useRef(null);
    const rowsContainerRef = useRef(null);
    const scrollDuplicatorRef = useRef(null);
    const lastColumnStickyStyleRef = useRef({ dataset: { styleid: `Table-last-sticky-${Math.random().toString(36).substr(2)}` } });
    const customColumnStickyStyleRef = useRef({ dataset: { styleid: `Table-custom-sticky-${Math.random().toString(36).substr(2)}` } });
    const [addColumnsOverlayActive, setAddColumnsOverlayState] = useState(false);
    const columnSelectorRef = useRef(null);

    const rows = _rows || table?.organizedRows || emptyRows;
    const $columns = _columns || table?.columns || [];
    const sortState = _sortState || table?.sortState;
    const toggleSort = _toggleSort || table?.toggleSort;
    const filterOptions = _filterOptions || table?.filterOptions || emptyFilterOptions;
    const filterState = _filterState || table?.filterState || emptyFilterState;
    const updateFilterState = _updateFilterState || table?.updateFilterState || _.noop;
    const selectionState = _selectionState || table?.selectionState;
    const toggleRowSelection = _toggleRowSelection || table?.toggleRowSelection;
    const rowState = _rowState || table?.rowState;
    const updateRowState = _updateRowState || table?.updateRowState;
    const selectAllRows = _selectAllRows || table?.selectAllRows;
    const columnState = _columnState || table?.columnState;
    const updateColumnState = _updateColumnState || table?.updateColumnState;
    const restoreColumnStateDefaults = _restoreColumnStateDefaults || table?.restoreColumnStateDefaults;

    const isRowSelectingEnabled = !!selectionState;

    const columns = React.useMemo(() => {
        const pColumns = $columns.map(column => ({ ...column, width: column.width || 1 }));

        if (rowMenu) {
            pColumns.push(menuColumn(rowMenu))
        }

        if(isRowSelectingEnabled) {
            pColumns.unshift(selectingColumn())
        }

        return pColumns;
    }, [$columns, rowMenu, isRowSelectingEnabled]);

    const [scrollDividerTop, setScrollDividerTop] = useState(0);
    const [hasScroll, setHasScroll] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);
    const [widths, setWidths] = useState(_.range(columns.length).map(() => 0));
    const [totalWidth, setTotalWidth] = useState(0);
    const [tableWidth, setTableWidth] = useState(0);
    const [scrollDividerLeft, setScrollDividerLeft] = useState(0);
    const [stickyTop, setStickyTop] = useState(undefined);

    const scrollRows = (e) => {
        const newScrollLeft = e.target.scrollLeft;
        if (headersContainerRef.current && rowsContainerRef.current) {
            headersContainerRef.current.scrollLeft = newScrollLeft;
            rowsContainerRef.current.scrollLeft = newScrollLeft;

            if (newScrollLeft > 0) {
                setIsScrolled(true);
                customColumnStickyStyleRef.current.innerHTML = `.${customColumnStickyStyleRef.current.dataset.styleid} {
                    z-index: 1;
                    transform: translateX(${newScrollLeft}px);
                }`;

                lastColumnStickyStyleRef.current.innerHTML = `.${lastColumnStickyStyleRef.current.dataset.styleid} {
                    z-index: 1;
                    transform: translateX(-${totalWidth - tableWidth - newScrollLeft}px);
                }`;
            } else if (newScrollLeft === 0) {
                setIsScrolled(false);
                customColumnStickyStyleRef.current.innerHTML = ``;
                lastColumnStickyStyleRef.current.innerHTML = `.${lastColumnStickyStyleRef.current.dataset.styleid} {
                    z-index: 1;
                    transform: translateX(-${totalWidth - tableWidth}px);
                }`;
            }
        }
    };

    useLayoutEffect(() => {
        const handleResize = () => {
            const width = tableRef.current.getBoundingClientRect().width;

            const totalWidth = _.sumBy(columns, "width");
            setWidths(_.map(columns, "width"));
            if (totalWidth > width) {
                setTotalWidth(totalWidth);
                setHasScroll(true);
            } else {
                setTotalWidth(width);
            }
            setTableWidth(width);

            setTimeout(() => {

                const childNodes = headersContainerRef?.current?.childNodes[0]?.childNodes
                // calculating the devider by summing all column widths up to column which equal to the sticky index
                const leftDivider = _.sumBy(_.take(childNodes, stickColumnIndex + 1), node => node?.getBoundingClientRect()?.width || 0);
                setScrollDividerLeft(leftDivider);

                lastColumnStickyStyleRef.current.innerHTML = `.${lastColumnStickyStyleRef.current.dataset.styleid} {
                    z-index: 1;
                    transform: translateX(-${totalWidth - width - (headersContainerRef?.current?.scrollLeft || 0)}px);
                }`;
            });
        };

        handleResize();

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [tableRef, setWidths, setTotalWidth, columns]);

    useLayoutEffect(() => {
        setScrollDividerTop(
            (headersContainerRef?.current?.childNodes[0]?.childNodes[0]?.getBoundingClientRect()?.height || 0)
        );
    }, []);


    const hasExpandedRow = useMemo(() => _.some(rowState, 'isExpanded'), [rowState])

    useEffect(() => {

        const lastColumnStyleId = lastColumnStickyStyleRef.current.dataset.styleid;
        lastColumnStickyStyleRef.current = window.document.createElement('style');
        lastColumnStickyStyleRef.current.type = 'text/css';
        lastColumnStickyStyleRef.current.dataset.styleid = lastColumnStyleId;

        const customColumnStyleId = customColumnStickyStyleRef.current.dataset.styleid;
        customColumnStickyStyleRef.current = window.document.createElement('style');
        customColumnStickyStyleRef.current.type = 'text/css';
        customColumnStickyStyleRef.current.dataset.styleid = customColumnStyleId;

        window.document.head.appendChild(lastColumnStickyStyleRef.current);
        window.document.head.appendChild(customColumnStickyStyleRef.current);

        return () => {
            window.document.head.removeChild(lastColumnStickyStyleRef.current);
            window.document.head.removeChild(customColumnStickyStyleRef.current);
        };
    }, []);

    return <>
        <div className={classNames(styles['base'], className)} ref={tableRef}>
            {isSticky && autoSticky && <Measure
                bounds
                onResize={contentRect => {
                    setStickyTop(contentRect.bounds.height + contentRect.bounds.top - (_.isNumber(autoSticky) ? autoSticky : 0));
                }}
            >
                {({ measureRef }) => <div ref={measureRef}/>}
            </Measure>}
            <div className={classNames(styles['headers-group-container'], headerContainerClassName, {[styles['sticky-header']]: isSticky})}
                 style={{ top: stickyTop, ...headerContainerStyle }} ref={headersContainerRef}>
                <BlankRow stickColumnIndex={stickColumnIndex} stickyColumnStyleRef={customColumnStickyStyleRef}
                          columns={columns} widths={widths}/>
                <div className={classNames(styles['header-row'], headerRowClassName)}>
                    {columns.map((column, index) => {
                        const stickLastColumn = lastColumnSticky && index === columns.length - 1;
                        const stickColumn = index <= stickColumnIndex;
                        const HeaderRenderer = column.HeaderRenderer || RenderHeaderCell;

                        return <HeaderRenderer headerCellClassName={headerCellClassName}
                                               headerHandleClassName={headerHandleClassName}
                                                 stickLastColumn={stickLastColumn}
                                                 lastColumnStickyStyleRef={lastColumnStickyStyleRef}
                                                 stickyColumnStyleRef={customColumnStickyStyleRef}
                                                 stickColumn={stickColumn}
                                                 selectionState={selectionState}
                                                 selectAllRows={selectAllRows}
                                                 columnFilterOptions={filterOptions[column.id]}
                                                 columnFilterState={filterState.columns[column.id]}
                                                 updateFilterState={updateFilterState}
                                                 columnIndex={index}
                                                 key={column.id}
                                                 width={widths ? widths[index] : 0}
                                                 column={column}
                                                 sortState={sortState}
                                                 toggleSort={toggleSort}/>
                    })}
                </div>
                {isTogglingColumns &&
                <ToggleColumnSelectorButton className={toggleColumnSelectorClassName} style={toggleColumnSelectorStyle}
                                            columnStickyStyleRef={customColumnStickyStyleRef}
                                            isActive={addColumnsOverlayActive}
                                            toggle={() => setAddColumnsOverlayState(!addColumnsOverlayActive)}/>}
            </div>
            {isTogglingColumns &&
            <CSSTransition nodeRef={columnSelectorRef} in={addColumnsOverlayActive} unmountOnExit timeout={400}
                           classNames={fadeClassNames}>
                <ColumnsSelector className={columnSelectorClassName} ref={columnSelectorRef} isSticky={isSticky}
                                 originalColumns={originalColumns} columnState={columnState}
                                 updateColumnState={updateColumnState}
                                 restoreColumnStateDefaults={restoreColumnStateDefaults}
                                 closeSelector={() => setAddColumnsOverlayState(false)}/>
            </CSSTransition>
            }
            {!isLoading && rows.length === 0 && <div className={styles['no-table-data']}>
                <NoDataIcon/>
                {noDataLabel}
            </div>}

            {hasScroll && isScrolled && stickColumnIndex >= 0 && !hasExpandedRow && <div className={scrollFixStyles['scroll-divider']} style={{
                top: scrollDividerTop,
                left: scrollDividerLeft
            }}/>}
            {addColumnsOverlayActive && <div className={styles['rows-group-cover']}/>}
            {(isLoading || rows.length > 0) && <div
                className={classNames(styles['rows-group-container'], rowsContainerClassName, { [styles['fixed-height']]: !!fixedHeight })}>
                <div ref={rowsContainerRef} style={fixedHeight ? { maxHeight: fixedHeight } : undefined}>
                    <RenderRows
                        isLoading={isLoading}
                        rows={rows}
                        columns={columns}
                        RenderRow={RenderRow}
                        widths={widths}
                        totalWidth={totalWidth}
                        tableWidth={tableWidth}
                        onRowClick={onRowClick}
                        onRowDoubleClick={onRowDoubleClick}
                        cellClassName={cellClassName}
                        selectionState={selectionState}
                        isRowSelectingEnabled={isRowSelectingEnabled}
                        rowState={rowState}
                        updateRowState={updateRowState}
                        toggleRowSelection={toggleRowSelection}
                        lastColumnSticky={lastColumnSticky}
                        lastColumnStickyStyleRef={lastColumnStickyStyleRef}
                        stickColumnIndex={stickColumnIndex}
                        stickyColumnStyleRef={customColumnStickyStyleRef}
                        SubRowRenderer={SubRowRenderer}
                        rowClassName={rowClassName}
                        isRowDisabled={isRowDisabled}
                        disabledTitle={disabledTitle}
                    />
                </div>
            </div>}
            <InView>
                {({ inView, ref, entry }) => {
                    return <>
                        <div ref={ref}/>
                        {!isLoading && hasScroll && <div
                            className={classNames(scrollFixStyles["scroll-duplicator"], { [scrollFixStyles["scroll-duplicator-sticky"]]: isSticky && !inView })}
                            style={{ width: tableWidth }} onScroll={(e) => scrollRows(e)} ref={scrollDuplicatorRef}>
                            <div style={{ width: totalWidth }}/>
                        </div>}
                    </>
                }}
            </InView>
        </div>
    </>
});

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.shape({
        header: PropTypes.string,
        headerClass: PropTypes.string,
        id: PropTypes.string,
        accessor: PropTypes.string,
        sort: PropTypes.shape({
            enabled: PropTypes.bool //default: true
        }),
        popoverText: PropTypes.string
    }).isRequired).isRequired,
    className: PropTypes.string,
    sortState: PropTypes.object,
    toggleSort: PropTypes.func,
    filterOptions: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    }))),
    filterState: PropTypes.object,
    updateFilterState: PropTypes.func,
    isLoading: PropTypes.bool
};

Table.displayName = "Table";

export default Table;
