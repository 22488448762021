import React from "react";
import classNames from "classnames";
import styles from "./../typography.module.scss";

export const Heading = ({children, level = "1", color, bold, italic, underline, center, ellipsis, nowrap, className, thin, ...rest}) => {
    const _className = classNames(styles[`h0${level}`], {[styles.b]: bold, [styles.u]: underline, [styles.thin]: thin, [styles.i]: italic, [styles.c]: center, [styles.ellipsis]: ellipsis, [styles[color]]: color}, className);

    return <div role="heading" className={_className} {...rest}>{children}</div>
}

export const Text = ({children, level = "14", color, bold, italic, center, ellipsis, nowrap, className, ...rest}) => {
    const _className = classNames(styles[`body${level}`], {[styles.b]: bold, [styles.i]: italic, [styles.c]: center, [styles.ellipsis]: ellipsis, [styles[color]]: color, [styles['no-wrap']]: nowrap}, className);

    return <p className={_className} {...rest}>{children}</p>
}

export const Span = ({children, level = "14", color, bold, italic, ellipsis, nowrap, className, ...rest}) => {
    const _className = classNames(styles[`body${level}`], {[styles.b]: bold, [styles.i]: italic, [styles.ellipsis]: ellipsis, [styles[color]]: color, [styles['no-wrap']]: nowrap}, className);

    return <span className={_className} {...rest}>{children}</span>
}

export const Notice = ({children, noticeLabel = "Notice", level = 14, className, style, bold, color = "warning"}) => {
    return <div className={className} style={style}>
        <Span bold={bold} color={color} level={level} className={styles.notice}>{noticeLabel}:</Span>
        <Span bold={bold} level={level}>{children}</Span>
    </div>
}
