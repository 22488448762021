import React from "react";
import classNames from "classnames";
import styles from "./RadioButton.module.scss";
import tooltips from "./../tooltips.module.scss";

export default ({ form, name, value, children, onChange: _onChange, checked: _checked, disabled, className, tooltip, ...rest }) => {
    let onChange = _onChange;
    let checked = _checked;
    if(form && name) {
        checked = checked || form.formState[name] === value;
        onChange = onChange || form.handleFormChange;
    }
    return (
        <label className={classNames(styles["base"], {[styles["disabled"]]: disabled, [styles["active"]]: checked}, className)}>
            <input checked={checked} onChange={onChange} type="radio" name={name} value={value} disabled={disabled} {...rest}/>
            <svg overflow="visible">
                <circle stroke="1" cx="8.5" cy="8.5" r="8" className={styles["outer-circle"]}/>
                <circle stroke="none" cx="8.5" cy="8.5" r="1" className={classNames(styles["inner-circle"])}/>
            </svg>
            {tooltip && <div className={classNames(styles.tooltip, tooltips.small)}>{tooltip}</div>}
            {children}
        </label>
    );
};
