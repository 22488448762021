import styles from "./ButtonsRow.module.scss";
import React from "react";

const ButtonsRow = ({children}) => {
    return <div className={styles.base}>
        {children}
    </div>
}

export default ButtonsRow;
