import _ from 'lodash'

const defaultState = () => ({
	error: null,
	isTableLoaded: false,
	tableData: null
});

export default (state = defaultState(), {payload, type}) => {
	switch (type){
		case "CSTABLE:LOAD-DATA-SUCCESS": {
			const now = new Date().toLocaleString('en-GB');
			return {...state,isTableLoaded:true,error:false,tableData:payload.data,updateTime:now}
		}
		case "CSTABLE:LOAD-DATA-FAILURE": {
			return {...state,isTableLoaded:true,error:payload.response.data.message||true}
		}
		case "ORGANIZATIONS:CHANGEORG": {
			return {...state,isTableLoaded:false}
		}
		default:
			break;
	}
	return state;
}
