import classNames from "classnames";
import styles from "./Table.module.scss";
import { ReactComponent as ToggleColumnSelector } from "./ic-table-add-column.svg";
import React from "react";

const ToggleColumnSelectorButton = ({ isActive, toggle, columnStickyStyleRef, className }) => {
    return <button type="button" onClick={toggle}
                   className={classNames(columnStickyStyleRef.current.dataset.styleid, styles['toggle-column-selector'], { [styles['active']]: isActive }, className)}>
        <ToggleColumnSelector /></button>
}

export default  ToggleColumnSelectorButton;