import React from "react";
import styles from "./SlidingManagementWidget.module.scss";
import {PopoverContext} from "../Popover";

import classNames from "classnames";
import { ReactComponent as BackArrow } from "./../../images/monochrome-icons/ic-arrow-no-circle.svg";
import typography from "./../typography.module.scss";

export const SlidingManagementWidgetBackButton = ({onClick, className}) => <button type="button" className={classNames(typography.body14, styles['back-button'], className)} onClick={onClick}>
    <BackArrow/><span>Back</span>
</button>;


export const SlidingManagementWidgetHeader = ({children}) => {
    return <header className={styles.header}>
        {children}
    </header>
} ;

export default ({children}) => {
    return <PopoverContext Component="section" className={styles.base}>
        {children}
    </PopoverContext>
}
