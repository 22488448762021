import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubTableComp from './SubTableComp';
import _ from 'lodash';
import classNames from 'classnames';
import { Heading, Text } from 'ui-components-rollup';
import Table, { TableCounter, TableWidgets, useTable } from 'ui-components-rollup/dist/Tables';
import { SearchWidget } from 'ui-components-rollup/dist/Widgets';
import { columnsGeneratorByLabels, floatRowsNum, compareColumns, compareDataObjNum } from '../utils/utils';
import styles from './pageStyle.module.scss';

const CostTableRegionsNew = () => {

    let [errorText, setErrorText] = useState(null);

    const dispatch = useDispatch();
    const summeryRow = useSelector(state => state.costTableRegions.summeryRow);
    const tableData = useSelector(state => state.costTableRegions.tableData);
    const isTableLoaded = useSelector(state => state.costTableRegions.isTableLoaded);
    const error = useSelector(state => state.costTableRegions.error);
    const selectedOrg = useSelector(state => state.organizations.selectedOrg);
    const initCol = {
        header: 'Service',
        accessor: 'Service',
        id: 'Service',
        sort: {
            enabled: true
        },
        width: 190
    };

    const columnsGenerator = useMemo(() => {
        return columnsGeneratorByLabels(initCol, tableData.labels);
    }, [tableData]);

    compareColumns(tableData.data, columnsGenerator)
   
    const {
        sortState,
        toggleSort,
        filterState,
        updateFilterState,
        rows,
        organizedRows,
        rowState,
        updateRowState,
        updateTextFilter,
        toggleRowSelection,
        columns
    } = useTable({
        columns: columnsGenerator,
        rows: tableData.data || [],
        isSorting: true,
        isRowSelecting: true,
    });

    const floatRows = useMemo(() => {
        return rows.map(obj => (
            Object.fromEntries(
                Object.entries(obj).map(
                    ([key, val]) => [key, _.isNumber(val) ? Math.round(val) : val]
                )
            )
        ));
    }, [rows]);

    const floatSummeryRow = useMemo(() => {
        const result = floatRowsNum(summeryRow);
        return result;
    }, [summeryRow]);

    const onRowClick = useMemo(() => {
        return (row) => {
            const rowUpdater = updateRowState(row.id);
            rowUpdater((rowState) => ({ isExpanded: !(rowState && rowState.isExpanded) }));
        };
    }, [tableData]);

    useEffect(() => {
        dispatch({ type: 'TABLEREGIONS:LOAD-DATA' });
    }, []);

    useEffect(() => {
        if (error) setErrorText(errorText = `Failed to get table:  ${_.isString(error) ? error : ''} `);
        if (!selectedOrg) setErrorText(errorText = 'Sorry you don\'t have accounts to monitor');
    }, [error, selectedOrg]);


    return <>
        <div className={styles['rt-container']}>
            <div className={styles['header']}>
                <Heading className={styles['header-title']}>Cost per region</Heading>
                <p className={styles['header-line']} />
                <Table
                    className={classNames(styles['summery-table'], styles['table-animation'])}
                    columns={columns}
                    isLoading={!isTableLoaded}
                    rows={floatSummeryRow}
                />
            </div>

            <div className={styles['table-widget']}>
                <TableCounter
                    className={styles['table-counter']}
                    filteredCount={organizedRows.length}
                    totalCount={rows.length}
                    pluralLabel="Connectors"
                    singularLabel="Connector" />
                <TableWidgets>
                    <SearchWidget setFilter={updateTextFilter} />
                </TableWidgets>
            </div>
            {errorText && <Text center bold>{errorText}</Text>}

            <Table
                columns={columns}
                rows={floatRows}
                rowState={rowState}
                isSticky headerContainerStyle={{ position: 'sticky', zIndex: 49, top: '15vh' }}
                isLoading={!isTableLoaded}
                className={styles['table-animation']}
                filterState={filterState}
                updateFilterState={updateFilterState}
                updateRowState={updateRowState}
                sortState={sortState}
                toggleSort={toggleSort}
                toggleRowSelection={toggleRowSelection}
                SubRowRenderer={SubTableComp}
                onRowClick={onRowClick}
            />
        </div>
    </>;
};
export default CostTableRegionsNew;