import React from "react";
import classNames from "classnames";
import styles from "./TableCounter.module.scss"

export default ({totalCount, filteredCount, singularLabel, pluralLabel, selectedCount, className, style}) => {
   return <div className={classNames(styles['base'], className)} style={style}>
       {(filteredCount && filteredCount > 0 && filteredCount < totalCount) ? <span className={styles['value']}>{filteredCount} / </span> : ""}
       <span className={styles['value']}>{totalCount}</span>
       <span className={styles['label']}>{totalCount === 1 ? singularLabel : pluralLabel}</span>
       {!!selectedCount && selectedCount > 0 && <span className={classNames(styles['label'], styles['selected-counter'])}>{selectedCount} selected {selectedCount === 1 ? singularLabel : pluralLabel}</span>}
   </div>
};