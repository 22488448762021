import React, {useMemo} from 'react';
import {useTable} from 'ui-components-rollup/dist/Tables';
import {SubTable} from 'ui-components-rollup';
import {compareDataObjNum, floatRowsNum, subTableColumnsGenerator, compareColumns} from '../utils/utils';


const SubTableComp = ({row}) => {

    const columnsGenerator = useMemo(() => {
        return subTableColumnsGenerator(row.MoreInfo);
    }, [row]);
    compareColumns(row.MoreInfo, columnsGenerator);

    const floatRow = useMemo(() => {
        return floatRowsNum(row.MoreInfo);
    }, [row]);

    floatRow.sort(compareDataObjNum);

    const {columns, rows} = useTable({columns: columnsGenerator, rows: floatRow || []});
    const tableSize = columns.length > 7 ? '95%' : '60%';

    return (
        <div style={{margin: '20px 43px 20px 37px', width: tableSize}}>
            <SubTable columns={columns} rows={rows}/>
        </div>
    )
        ;
};
export default SubTableComp;
