import classNames from "classnames";
import styles from "./MultiCheckboxSelect.module.scss";
import React from "react";
import {components} from 'react-select';
import FormFieldSelect, { getOptions } from "./FormFieldSelect";
import _ from "lodash";
import Checkbox from "./Checkbox";
import {ReactComponent as CloseIcon} from "./../../images/monochrome-icons/ic-close.svg";

const {Option, MultiValueContainer, MultiValueRemove} = components;

const selectStyle = {
    valueContainer: base => ({
        ...base,
        padding: '0px 15px',
        overflow: "visible"
    })
};

const selectComponents = {
    ...getOptions(),
    Option: (props) => {
        const {label, isSelected} = props;
        return <Option {...props} className={classNames(styles["select-option"])}>
            <Checkbox checked={isSelected} className={classNames({[styles["active-option"]]: isSelected})}>
                {label}
            </Checkbox>
        </Option>
    },
    MultiValueContainer: (props) => {
        const { data, selectProps: {value: multiValues} } = props;
        if(data === multiValues[0]) {
            props.innerProps.className = classNames(props.innerProps.className, styles["first-multi-value"]);
            return <MultiValueContainer {...props}/>
        } else if (data === multiValues[1]) {
            return <div className={styles["rest-multi-value"]}>
                    +{multiValues.length - 1}
                </div>
        } else {
            return <span/>
        }
    },
    MultiValueLabel: ({data}) => {
        return <>
            <div className={styles['multi-value-label']}>{data.label}</div>
            <div className={styles['multi-value-label-tooltip']}>{data.label}</div>
        </>
    },
    MultiValueRemove: (props) => {
        props.innerProps.className = classNames(props.innerProps.className, styles['multi-value-remove']);
        return <MultiValueRemove {...props}>
            <CloseIcon/>
      </MultiValueRemove>
    },
    ClearIndicator: () => {
        return <span/>
    },
    Menu: (props) => {
        return <components.Menu {...props}/>
    }
};

const joinedListSelectComponents = {
    ...selectComponents,
    MultiValueContainer: (props) => {
        const { data, selectProps: {value: multiValues} } = props;
        if(data === multiValues[0]) {
            const values = _(multiValues).map("value").sortBy().value();

            const ranges = [];
            let index = 0;
            while (index < values.length) {
                const rangeStartIndex = index;
                while (values[index + 1] === values[index] + 1) {
                    index++;
                }

                if (rangeStartIndex === index) {
                    ranges.push(values[index]);
                } else {
                    ranges.push(values[rangeStartIndex] + " - " + values[index]);
                }
                index++;
            }


            return <div className={styles["joined-list"]}>
                {ranges.join(", ")}
            </div>
        } else {
            return <span/>
        }
    }
};

export default (props) => {
    const { multiValueType } = props;
    const components = multiValueType === "joined-list" ? joinedListSelectComponents : selectComponents;

    const isMulti = props.isMulti !== undefined ? props.isMulti : true;
    const closeMenuOnSelect = props.closeMenuOnSelect !== undefined ? props.closeMenuOnSelect : !isMulti;
    const hideSelectedOptions = props.hideSelectedOptions !== undefined ? props.hideSelectedOptions : false;

    return <FormFieldSelect
        {...props}
        isMulti={isMulti}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={hideSelectedOptions}
        components={components}
        selectStyle={selectStyle}
        classNamePrefix="multi-checkbox-select"
    />
}
