import {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import SubTableComp from './SubTableComp';
import _ from 'lodash';
import {Heading, Text} from 'ui-components-rollup';
import Table, {TableCounter, TableWidgets, useTable} from 'ui-components-rollup/dist/Tables';
import {SearchWidget} from 'ui-components-rollup/dist/Widgets';
import styles from './pageStyle.module.scss';


const EC2InstancesTableNew = () => {

    let [errorText, setErrorText] = useState(null);
    const dispatch = useDispatch();
    const tableData = useSelector(state => state.ec2Table.tableData);
    const isTableLoaded = useSelector(state => state.ec2Table.isTableLoaded);
    const error = useSelector(state => state.ec2Table.error);
    const selectedOrg = useSelector(state => state.organizations.selectedOrg);
    const initCol = [{
        header: 'Account',
        accessor: 'Account',
        id: 'Account',
        sort: {
            enabled: false
        },
        filter: {
            enabled: true,
            RenderFilter: 'dropdown',
        },
        width: 140
    },
        {
            header: 'Region',
            accessor: 'Region',
            id: 'Region',
            sort: {
                enabled: false
            },
            filter: {
                enabled: true,
                RenderFilter: 'dropdown',
            },
            width: 100
        },

        {
            header: 'State',
            accessor: 'State',
            id: 'State',
            sort: {
                enabled: false
            },
            filter: {
                enabled: true,
                RenderFilter: 'dropdown',
            },
            width: 100
        },
        {
            header: 'Instance type',
            accessor: 'InstanceType',
            id: 'InstanceType',
            sort: {
                enabled: true
            },
            width: 100
        },
        {
            header: 'Instances id',
            accessor: 'InstancesId',
            id: 'InstancesId',
            sort: {
                enabled: true
            },
            width: 100
        },
        {
            header: 'Instances name',
            accessor: 'InstancesName',
            id: 'InstancesName',
            sort: {
                enabled: true
            },
            width: 100
        },
        {
            header: 'Instances owner',
            accessor: 'InstancesOwner',
            id: 'InstancesOwner',
            sort: {
                enabled: true
            },
            width: 100
        },


        {
            header: 'Tags',
            accessor: 'Tags',
            id: 'Tags',
            sort: {
                enabled: true
            },
            width: 10
        },
    ];

    const {
        sortState,
        toggleSort,
        filterState,
        updateFilterState,
        rows,
        organizedRows,
        rowState,
        updateRowState,
        updateTextFilter,
        toggleRowSelection,
        columns
    } = useTable({
        columns: initCol,
        rows: tableData.data || [],
        isSorting: true,
        isRowSelecting: true,
    });

    const filterOptions = useMemo(() => {
        return {
            Region: _.uniqBy(rows.map((row) => {
                return {label: row.Region || 'N/A', value: row.Region};
            }), 'value'),
            Account: _.uniqBy(rows.map((row) => {
                return {label: row.Account || 'N/A', value: row.Account};
            }), 'value'),
            State: [{label: 'running', value: 'running'}, {label: 'stopped', value: 'stopped'}]
        };
    }, [rows]);


    const onRowClick = useMemo(() => {
        return (row) => {
            const rowUpdater = updateRowState(row.id);
            rowUpdater((rowState) => ({isExpanded: !(rowState && rowState.isExpanded)}));
        };
    }, [tableData]);


    useEffect(() => {
        dispatch({type: 'EC2TABLE:LOAD-DATA'});
    }, [dispatch]);

    useEffect(() => {
        if (error) setErrorText(errorText = `Failed to get table:  ${_.isString(error) ? error : ''} `);
        if (!selectedOrg) setErrorText(errorText = 'Sorry you don\'t have accounts to monitor');
    }, [error, selectedOrg]);

    return <>
        <div className={styles['rt-container']}>
            <div className={styles['header']}>
                <Heading className={styles['header-title']}>EC2 keep alive</Heading>
                <p className={styles['header-line']}/>
            </div>
            <div className={styles['table-widget']}>
                <TableCounter
                    className={styles['table-counter']}
                    filteredCount={organizedRows.length}
                    totalCount={rows.length}
                    pluralLabel="Connectors"
                    singularLabel="Connector"/>
                <TableWidgets>
                    <SearchWidget setFilter={updateTextFilter}/>
                </TableWidgets>
            </div>
            {errorText && <Text center bold>{errorText}</Text>}

            <Table
                columns={columns}
                rows={organizedRows}
                rowState={rowState}
                isSticky headerContainerStyle={{position: 'sticky', zIndex: 49, top: '15vh'}}
                isLoading={!isTableLoaded}
                className={styles['table-animation']}

                filterOptions={filterOptions}
                filterState={filterState}
                updateFilterState={updateFilterState}
                updateRowState={updateRowState}
                sortState={sortState}
                toggleSort={toggleSort}
                toggleRowSelection={toggleRowSelection}
                SubRowRenderer={SubTableComp}
                onRowClick={onRowClick}
            />
        </div>
    </>;
};

export default EC2InstancesTableNew;