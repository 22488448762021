import {useEffect, useMemo, useState} from 'react';
import Table, {TableCounter, TableWidgets, useTable} from 'ui-components-rollup/dist/Tables';
import {SearchWidget} from 'ui-components-rollup/dist/Widgets';
import {Heading, Text} from 'ui-components-rollup';
import {useDispatch, useSelector} from 'react-redux';
import _ from 'lodash';
import styles from './pageStyle.module.scss';
import classNames from 'classnames';
import {columnsGeneratorByLabels as columnsGeneratorByLabels, floatRowsNum} from '../utils/utils';
import SubTableComp from './SubTableComp';

const CostTableAccountsNew = () => {

    let [errorText, setErrorText] = useState(null);
    const dispatch = useDispatch();
    const summeryRow = useSelector(state => state.costTableAccounts.summeryRow);
    const tableData = useSelector(state => state.costTableAccounts.tableData);
    const isTableLoaded = useSelector(state => state.costTableAccounts.isTableLoaded);
    const error = useSelector(state => state.costTableAccounts.error);
    const selectedOrg = useSelector(state => state.organizations.selectedOrg);
    const initCol = {
        header: 'Account',
        accessor: 'Account',
        id: 'Account',
        sort: {
            enabled: true
        },
        width: 150
    };

    const columnsGenerator = useMemo(() => {
        return columnsGeneratorByLabels(initCol, tableData.labels);
    }, [tableData]);

    const {
        sortState,
        toggleSort,
        filterState,
        updateFilterState,
        rows,
        organizedRows,
        rowState,
        updateRowState,
        updateTextFilter,
        toggleRowSelection,
        columns
    } = useTable({
        columns: columnsGenerator,
        rows: tableData.data || [],
        isSorting: true,
        isRowSelecting: true,
    });


    const floatOrganizedRows = useMemo(() => {
        return floatRowsNum(organizedRows);
    }, [organizedRows]);

    const floatSummeryRow = useMemo(() => {
        return floatRowsNum(summeryRow);
    }, [summeryRow]);

    const onRowClick = useMemo(() => {
        return (row) => {
            const rowUpdater = updateRowState(row.id);
            rowUpdater((rowState) => ({isExpanded: !(rowState && rowState.isExpanded)}));
        };
    }, [tableData]);


    useEffect(() => {
        dispatch({type: 'TABLEACCOUNTS:LOAD-DATA'});
    }, []);

    useEffect(() => {
        if (error) setErrorText(errorText = `Failed to get table:  ${_.isString(error) ? error : ''} `);
        if (!selectedOrg) setErrorText(errorText = 'Sorry you don\'t have accounts to monitor');
    }, [error, selectedOrg]);

    return <>
        <div className={styles['rt-container']}>
            <div className={styles['header']}>
                <Heading className={styles['header-title']}>Cost account per month</Heading>
                <p className={styles['header-line']}/>
                <Table
                    className={classNames(styles['summery-table'], styles['table-animation'])}
                    columns={columns}
                    isLoading={!isTableLoaded}
                    rows={floatSummeryRow}
                />
            </div>

            <div className={styles['table-widget']}>
                <TableCounter
                    className={styles['table-counter']}
                    filteredCount={organizedRows.length}
                    totalCount={rows.length}
                    pluralLabel="Connectors"
                    singularLabel="Connector"/>
                <TableWidgets>
                    <SearchWidget setFilter={updateTextFilter}/>
                </TableWidgets>
            </div>
            {errorText && <Text center bold>{errorText}</Text>}
            <Table
                columns={columns}
                rows={floatOrganizedRows}
                rowState={rowState}
                isSticky headerContainerStyle={{position: 'sticky', zIndex: 49, top: '15vh'}}
                isLoading={!isTableLoaded}
                className={styles['table-animation']}
                filterState={filterState}
                updateFilterState={updateFilterState}
                updateRowState={updateRowState}
                sortState={sortState}
                toggleSort={toggleSort}
                toggleRowSelection={toggleRowSelection}
                SubRowRenderer={SubTableComp}
                onRowClick={onRowClick}
            />
        </div>
    </>;
};


export default CostTableAccountsNew;