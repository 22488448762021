import React from "react";
import styles from "./ComponentLoader.module.scss";
import classNames from "classnames";

export default ({className, style}) => {
  return <div className={classNames(styles['base'], className)} style={style}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" overflow="visible">
          <circle className={styles['primary-stroke']} cx="25" cy="25" r="20" fill="none" strokeWidth="5"/>
      </svg>
  </div>
};