import React, { useMemo, useLayoutEffect, useRef, useState } from "react";
import PopoverMechanism, { closeActivePopover } from "./PopoverMechanism"
import classNames from "classnames";
import styles from "./MenuPopover.module.scss";
import {ReactComponent as MenuIcon } from "./../../images/monochrome-icons/ic-menu-circle.svg";
import {ReactComponent as OvalIcon } from "./../../images/monochrome-icons/ic-oval-circle.svg";
import _ from "lodash";
import typography from "../typography.module.scss";
import {CopyButton} from "./../Widgets";
import tooltips from "../tooltips.module.scss";
import buttons from "./../buttons.module.scss";

export const MenuItem = ({children, onClick, disabled, tooltip}) => {
    return <button type="button" className={styles["popover-menu-item"]} disabled={disabled} onClick={(e) => {
        const keepMenuOpen = onClick(e);
        if(!keepMenuOpen) {
            closeActivePopover();
        }
    }}>
        {children}
        {tooltip && <div className={classNames(styles.tooltip, tooltips.small)}>{tooltip}</div>}
    </button>
};

export const CopyMenuItem = ({value, label, hideValue}) => {
    return <span className={styles["copy-menu-item"]}>
        <span className={typography.ellipsis}>{label}{!hideValue && `: ${value}`}</span>
        <CopyButton text={value}/>
    </span>
};

const Popover = ({arrowClassName, arrowStyle, contentClassName, contentStyle, menu: _menu, menuChildren}) => {
    const [verticalPlacement, setVerticalPlacement] = useState(false);
    const menuRef = useRef();
    const menu = useMemo(() => {
        if(menuChildren){
            if(_.isArray(menuChildren)) {
                return menuChildren
            } else {
                return [menuChildren]
            }
        } else {
            return _.filter(_.isFunction(_menu) ? _menu() : _menu, _.identity);
        }
    }, [_menu, menuChildren]);

    useLayoutEffect(() => {
        if(menuRef.current) {
            const rect = menuRef.current.getBoundingClientRect();
            if(rect.height + rect.top > window.innerHeight ) {
                setVerticalPlacement(true);
            }
        }
    }, [menu])

    return <>
        <div className={classNames(styles["popover-arrow"], {[styles['popover-arrow-vertical']]: verticalPlacement}, arrowClassName)} style={arrowStyle}/>
        <div className={classNames(styles["popover-menu"], {[styles['popover-menu-vertical']]: verticalPlacement},contentClassName)} style={contentStyle} ref={menuRef}>
            {menuChildren && menu.map((child, index) => {
                if(!child){
                    return ""
                }
                const needClone = child.type !== MenuItem && child.type !== CopyMenuItem && !child?.props?.className;
                if(needClone && child?.props.disabled) {
                    return <MenuItem key={index} tooltip={child.props.tooltip} disabled={true}>
                        {child?.props.children}
                    </MenuItem>
                }

                const clone = needClone ? React.cloneElement(child, {
                    className: styles["popover-menu-item"],
                    onClick: (e) => {
                        const keepMenuOpen = child.onClick ? child.onClick(e) : false;
                        if(!keepMenuOpen) {
                            closeActivePopover();
                        }
                    }
                }) : child;
                return <React.Fragment key={index}>
                    {clone}
                </React.Fragment>
            })}
            {!menuChildren && menu.map(({key, label, onClick, Component, Icon, iconStyle, disabled, tooltip}, index) => {
                if (Component) {
                    return <Component key={key || label || index} className={styles["popover-menu-item"]}/>
                } else if(label) {
                    return <MenuItem key={key || label || index} onClick={onClick} disabled={disabled} tooltip={tooltip}>
                        {Icon && <span><Icon style={iconStyle}/></span>}{label}
                    </MenuItem>
                }
            })}
        </div>
    </>;
};

const OvalTrigger = ({isActive}) => {
    return <span className={classNames(styles['oval-trigger'], {[styles['oval-trigger-active']]: isActive})}>
        <OvalIcon/>
    </span>

};

const BigTrigger = ({isActive}) => {
    return <MenuIcon className={classNames(styles['large-trigger'], {[styles['trigger-active']]: isActive})}/>
};

const SmallTrigger = ({isActive}) => {
    return <MenuIcon className={classNames(styles['small-trigger'], {[styles['trigger-active']]: isActive})}/>
};

export const PopoverButtonTrigger = ({buttonRef, isActive, children, color = "primary", large, disabled, onClick}) => {
    return <button onClick={onClick} ref={buttonRef} type="button" disabled={disabled} className={classNames(buttons[color], styles[color], styles['popover-button-trigger'], {[buttons.large]: large})}>
        <span className={styles['popover-button-trigger-text']}>{children}</span>
        <span className={classNames(styles['popover-button-trigger-triangle'], {[styles["active"]]: isActive})}/>
    </button>
}

export const RoundPopoverButtonTrigger = ({buttonRef, isActive, children, color = "primary", large, disabled, onClick, style}) => {
    return <button onClick={onClick} ref={buttonRef} type="button" disabled={disabled} className={classNames(buttons[color], styles[color], styles['round-popover-button-trigger'], {[buttons.large]: large})} style={style}>
        <span className={styles['round-popover-button-trigger-text']}>{children}</span>
        <span className={classNames(styles['popover-button-trigger-triangle'], {[styles["active"]]: isActive})}/>
    </button>
}



/**
 *
 triggerClassName,
 popoverClassName, popoverStyle, children,
 arrowClassName,
 arrowStyle,
 contentClassName,
 contentStyle,
 menu
 */
const MenuPopover = ({popoverClassName, Trigger: _Trigger, TriggerButton, children, disabled, ...props}) => {
    let Trigger = BigTrigger;

    if(_Trigger === "oval") {
        Trigger = OvalTrigger
    } else if(_Trigger === "small") {
        Trigger = SmallTrigger
    } else if (_Trigger) {
        Trigger = _Trigger;
    }

    return <PopoverMechanism popoverClassName={classNames(styles['menu-popover'], popoverClassName)} disabled={disabled} {...props} menuChildren={children} Popover={Popover} Trigger={Trigger} TriggerButton={TriggerButton} triggerOnClick={true}/>
};

export const ButtonStyleMenuPopover = ({round, buttonChildren, large, color, popoverClassName, ...props}) => {
    const TriggerButton = round ? RoundPopoverButtonTrigger : PopoverButtonTrigger;
    return <MenuPopover {...props} popoverClassName={classNames(styles['button-style-menu-popover'], popoverClassName)} renderPosition="right" TriggerButton={props => <TriggerButton {...props} large={large} color={color}>{buttonChildren}</TriggerButton>}/>
}

export default MenuPopover;