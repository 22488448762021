import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import ReactDOM from "react-dom";
import TextTruncate from 'react-text-truncate';
import styles from "./Modal.module.scss"
import { ReactComponent as CloseXIcon } from './../../images/monochrome-icons/ic-close.svg';
import { Span } from "../Typography"
import classNames from 'classnames';
import _ from "lodash";
import AnimateHeight from "react-animate-height";
import { PopoverContext } from "../Popover";

let lastTarget = null;
const Container = ({ target, children, closeTrigger, className, style: _style, form }) => {
    const [style, setStyle] = useState({ ..._style });
    const modalRef = useRef();

    useEffect(() => {
        const closeDialogListener = e => {
            if (e.keyCode === 8 && e.target.nodeName !== "INPUT" && e.target.nodeName !== "TEXTAREA") {
                e.preventDefault();
            }

            if (e.keyCode === 27) {
                closeTrigger && closeTrigger();
            }
        };

        document.addEventListener('keydown', closeDialogListener);

        return () => {
            document.removeEventListener('keydown', closeDialogListener);
        }
    }, [closeTrigger]);

    useLayoutEffect(() => {
        let actualTarget = null;
        if (_.isFunction(target)) {
            actualTarget = target()
        } else if (target) {
            actualTarget = target;
        } else if (lastTarget && lastTarget.isConnected) {
            actualTarget = lastTarget;
        } else {
            actualTarget = document.body;
        }
        lastTarget = null;

        if (actualTarget && modalRef.current) {
            const targetBoundingRect = actualTarget.getBoundingClientRect();
            const modalRect = modalRef.current.getBoundingClientRect();

            const x = (targetBoundingRect.left + targetBoundingRect.width / 2) - modalRect.left;
            const y = (targetBoundingRect.top + targetBoundingRect.height / 2) - (modalRect.top + modalRect.height);
            setStyle({
                opacity: 0,
                transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px)) scale(0)`,
                ..._style
            });

            setTimeout(() => {
                setStyle({
                    transition: "transform 0.5s cubic-bezier(0.25, 0.8, 0.25, 1)",
                    transformOrigin: "0 100%",
                    opacity: 1,
                    transform: "translate(-50%, -50%)",
                    ..._style
                })
            }, 100);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const Component = form ? "form" : "div";

    return <PopoverContext className={styles['modal-container']}>
        <div className={styles['mask']} onClick={closeTrigger}/>
        <Component className={classNames(styles["modal"], className)} ref={modalRef} style={style} onSubmit={form ? (e) => {
            e.preventDefault();
            return false;
        } : undefined}>
            {children}
        </Component>
    </PopoverContext>;
};

export const Content = ({ children, className, style }) => {
    return <div className={classNames(styles["content"], className)} style={style}>
        {children}
    </div>
};

export const SimpleActionFooter = ({ children, error: _error, className }) => {
    let error = _error;
    if (_.isObject(_error)) {
        error = _error.description;
        if (_error.moreInfo) {
            error = error + ": " + _error.moreInfo;
        }
    }

    return <footer className={classNames(styles["footer"], className)}>
        <ErrorRow>
            {error && <>
                <Span bold color="error" style={{ marginRight: 6 }} level={13}>Error:</Span>
                <TextTruncate title={error}
                              line={2}
                              text={error}
                />
            </>}
        </ErrorRow>
        {children}
    </footer>
};

export const ActionFooter = ({ children, error }) => {
    return <SimpleActionFooter error={error}>
        <ButtonRow>
            {children}
        </ButtonRow>
    </SimpleActionFooter>
};

export const SimpleFooter = ({ children, className, ...rest }) => {
    return <footer className={classNames(styles["footer"], className)} {...rest}>
        {children}
    </footer>
};

export const Footer = ({ children }) => {
    return <footer className={styles["footer"]}>
        <ButtonRow>
            {children}
        </ButtonRow>
    </footer>
};

export const ErrorRow = ({ children }) => {
    return <AnimateHeight
        duration={400}
        easing="cubic-bezier(.25,.8,.25,1)"
        height={children ? "auto" : 0}
        className={styles["error-row"]}
        contentClassName={styles["error-row-grid"]}
    >
        {children || ""}
    </AnimateHeight>
};

export const ButtonRow = ({ children, className }) => {
    return <div className={classNames(styles["button-row"], className)}>
        {children}
    </div>
};

export const SimpleButtonRow = ({ children, className }) => {
    return <div className={classNames(styles["simple-button-row"], className)}>
        {children}
    </div>
}

export const Header = ({ children, closeTrigger }) => {
    return <header className={styles.header}>
        {children}
        {closeTrigger && <button onClick={closeTrigger} className={styles['close-button']}><CloseXIcon/></button>}
    </header>
};

export const initModalTargetListener = _.once(() => {
    document.body.addEventListener("click", (e) => {
        lastTarget = e.target
    }, true);
});

export default ({ target, children, closeTrigger, className, style, form }) => {
    return ReactDOM.createPortal(<Container className={className} style={style} target={target}
                                            form={form} closeTrigger={closeTrigger}>{children}</Container>, document.body);
};
