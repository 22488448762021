import styles from "./ColumnsSelector.module.scss"
import ButtonsRow from "./../Widgets/ButtonsRow";
import _ from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { ClickAway, DisableScroll } from "./../Functional";
import { Checkbox } from "./../Forms"
import classNames from "classnames";

const ColumnsSelector = React.forwardRef(({isSticky, originalColumns, columnState, restoreColumnStateDefaults, updateColumnState, closeSelector, className}, ref) => {
    const [columnSelection, setColumnSelection] = useState(_.mapValues(columnState, column => column.isHidden));
    const [checkAll, setCheckAll] = useState(false);
    const columns = useMemo(() => {
        return _.reject(originalColumns, "hideFromColumnSelector");
    }, [originalColumns])
    const columnsMap = _.keyBy(originalColumns, "id");

    useEffect(() => {
        setColumnSelection(_.mapValues(columnState, column => column.isHidden));
    }, [columnState]);

    return <div className={classNames(styles.base, className)} ref={ref}>
        <div className={styles["popover-arrow"]}/>
        <ClickAway ignoreParentClick={true} listener={() => {
            closeSelector();
        }}/>
        {isSticky && <DisableScroll/>}

        <div className={styles.content}>
            <Checkbox onChange={() => {
                setColumnSelection(_.mapValues(columnSelection, () => false));
                setCheckAll(true);
            }} checked={checkAll}>{`All (${columns.length})`}</Checkbox>
            {_.map(columns, ({id}) => {
                return <Checkbox disabled={columnsMap[id].mustShow} key={id} onChange={() => {
                    setColumnSelection({...columnSelection, [id]: !columnSelection[id]});
                    setCheckAll(false);
                }} checked={!columnSelection[id]}>{columnsMap[id].header}</Checkbox>
            })}
        </div>

        <ButtonsRow>
            <button type="button" onClick={() => {
                restoreColumnStateDefaults();
                setCheckAll(false);
                closeSelector();
            }}>Restore Defaults</button>
            <button type="button" onClick={() => {
                const newColumnState = { ...columnState };
                _.forEach(columnSelection, (isHidden, id) => {
                    newColumnState[id] = {...columnState[id], isHidden}
                })
                updateColumnState(newColumnState);
                closeSelector();
            }}>Apply</button>
        </ButtonsRow>
    </div>
})

export default ColumnsSelector;
