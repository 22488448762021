import { Text } from 'ui-components-rollup';

const SubRow = ({ row }) => {
    return (
        <div style={{ margin: '20px 43px 20px 37px', display: 'flex', flexDirection: 'row' }}>
            <Text bold style={{ margin: '0 10px 0 0 ' }}>Description: </Text>
            <Text>  {row.Description}</Text>
        </div>
    )
};

export default SubRow
