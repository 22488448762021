import { combineReducers } from 'redux'
import authReducer from "./authReducer";
import costMapReducer from './costMapReducer';
import costChartReducer from './costChartReducer';
import costTableAccountsReducer from './costTableAccountsReducer';
import costTableRegionsReducer from './costTableRegionsReducer';
import EC2InstancesTableReducer from './ec2InstancesTableReducer';
import guardDutyReducer from './guardDutyReducer';
import costTableRegionsAzureReducer from './costTableRegionsAzureReducer';
import azureAdvisorTableReducer from './azureAdvisorTableReducer';
import organizationsReducer from './organizationsReducer';
import configServiceReducer from './configServiceReducer'
import styleReducer from './styleReducer'

export default () => combineReducers({
    auth: authReducer,
    costMap: costMapReducer,
    costChart: costChartReducer,
    costTableAccounts: costTableAccountsReducer,
    costTableRegions: costTableRegionsReducer,
    ec2Table: EC2InstancesTableReducer,
    gdTable: guardDutyReducer,
    costTableRegionsAzure: costTableRegionsAzureReducer,
    organizations: organizationsReducer,
    azureAdvisorTable: azureAdvisorTableReducer,
    configServiceTable: configServiceReducer,
    style: styleReducer
})
