import React from "react";
import tableStyles from "./Table.module.scss";
import { Checkbox, RadioButton } from "../Forms";
import styles from "./Table.module.scss";
import classNames from "classnames";

const selectingColumn = () => {
    return {
        id: "rowSelector",
        width: 32,
        fixedWidth: true,
        header: "",
        accessor: "",
        cellClass: tableStyles['selection-cell'],
        Renderer: ({isRowSelected, selectRow, isMultiSelect, disabled}) => {
            return <>
                {isMultiSelect && <Checkbox checked={isRowSelected || false} name="row" className={styles['cell-checkbox']} onChange={() => {
                    selectRow(!isRowSelected)
                }}/>}
                {!isMultiSelect && <RadioButton disabled={disabled} checked={isRowSelected || false} name="row" className={styles['cell-checkbox']} onChange={() => {
                    selectRow(true)
                }}/>}
            </>
        },
        HeaderRenderer: ({column, width, selectAllRows, selectionState, headerCellClassName, stickLastColumn, stickColumn, lastColumnStickyStyleRef, stickyColumnStyleRef}) => {
            const className = classNames(column.headerClass, styles['header-cell'], headerCellClassName, styles['is-selecting'], {
                [lastColumnStickyStyleRef.current.dataset.styleid]: stickLastColumn,
                [stickyColumnStyleRef.current.dataset.styleid]: stickColumn
            });

            const style = {
                flex: column.fixedWidth ? `0 0 ${width}px` : `1 0 ${width}px`
            };

            return <div className={className} style={style}>
                {selectAllRows && <Checkbox checked={selectionState?.allSelected || false} onChange={() => {
                    selectAllRows(!selectionState.allSelected)
                }} className={styles['header-is-selecting-indicator']} />}
            </div>
        }
    }
};

export default selectingColumn;
