import React, { useEffect, useMemo, useState } from 'react';
import Table, { TableCounter, TableWidgets, useTable } from 'ui-components-rollup/dist/Tables';
import { SearchWidget } from 'ui-components-rollup/dist/Widgets';
import { Heading, Text } from 'ui-components-rollup';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import styles from './pageStyle.module.scss';

const ConfigServiceTableNew = () => {

    let [errorText, setErrorText] = useState(null);
    const dispatch = useDispatch();
    const isTableLoaded = useSelector(state => state.configServiceTable.isTableLoaded);
    const tableData = useSelector(state => state.configServiceTable.tableData);
    const error = useSelector(state => state.configServiceTable.error);
    const selectedOrg = useSelector(state => state.organizations.selectedOrg);
    const Columns = [
        {
            header: 'Account',
            accessor: 'account',
            id: 'account',
            width: 100,
            sort: {
                enabled: false
            },
            filter: {
                enabled: true,
                RenderFilter: 'dropdown',
            }
        },
        {
            header: 'Region',
            accessor: 'region',
            id: 'region',
            width: 100,
            sort: {
                enabled: false
            },
            filter: {
                enabled: true,
                RenderFilter: 'dropdown',
            }
        },
        {
            header: 'Config rule name',
            accessor: 'configRuleName',
            id: 'configRuleName',
            sort: {
                enabled: true
            },
            width: 225
        }, {
            header: 'Compliance',
            accessor: 'compliance',
            id: 'compliance',
            sort: {
                enabled: true
            },
            width: 225
        },
    ];


    const {
        sortState,
        toggleSort,
        filterState,
        updateFilterState,
        rows,
        organizedRows,
        rowState,
        updateRowState,
        updateTextFilter,
        toggleRowSelection,
        columns
    } = useTable({
        columns: Columns,
        rows: tableData,
        isSorting: true,
        isRowSelecting: true
    });

    const filterOptions = useMemo(() => {
        return {
            region: _.uniqBy(rows.map((row) => {
                return { label: row.region || 'N/A', value: row.region };
            }), 'value'),
            account: _.uniqBy(rows.map((row) => {
                return { label: row.account || 'N/A', value: row.account };
            }), 'value'),
        };
    }, [rows]);


    useEffect(() => {
        dispatch({ type: 'CSTABLE:LOAD-DATA' });
    }, [dispatch]);

    useEffect(() => {
        if (error) setErrorText(errorText = `Failed to get table:  ${_.isString(error) ? error : ''} `);
        if (!selectedOrg) setErrorText(errorText = 'Sorry you don\'t have accounts to monitor');
    }, [error, selectedOrg]);


    return <>
        <div className={styles['rt-container']}>
            <div className={styles['header']}>
                <Heading className={styles['header-title']}>Config service</Heading>
                <p className={styles['header-line']} />
            </div>
            <div className={styles['table-widget']}>
                <TableCounter
                    filteredCount={organizedRows.length}
                    className={styles['table-counter']}
                    totalCount={rows.length}
                    pluralLabel="Config rule"
                    singularLabel="Config rule" />
                <TableWidgets>
                    <SearchWidget setFilter={updateTextFilter} />
                </TableWidgets>
            </div>
            {errorText && <Text center bold>{errorText}</Text>}
            <Table
                className={styles['table-animation']}
                isSticky
                headerContainerStyle={{ position: 'sticky', zIndex: 49, top: '15vh' }}
                columns={columns}
                rows={errorText ? [] : organizedRows}
                isLoading={!isTableLoaded}
                rowState={rowState}
                updateRowState={updateRowState}
                toggleRowSelection={toggleRowSelection}
                filterOptions={filterOptions}
                filterState={filterState}
                updateFilterState={updateFilterState}
                sortState={sortState}
                toggleSort={toggleSort} />
        </div>
    </>;

};

export default ConfigServiceTableNew;