import _ from 'lodash';

const toggleClass = (element, className) => {
    if (element.classList.contains(className)) {
        element.classList.remove(className);
    } else {
        element.classList.add(className);
    }
};

export const onClickRow = (state, rowInfo, column, instance) => {
    return {
        onClick: (e) => {
            let selection = window.getSelection();
            if (selection.type === 'Range' && selection.toString().trim()) {
                return;
            }
            const rowElement = e.currentTarget.closest('.rt-tr-group');
            rowElement && toggleClass(rowElement, 'max-height-none');

            const {expanded} = state;
            const path = rowInfo.nestingPath[0];
            const diff = {[path]: expanded[path] ? false : true};

            instance.setState({
                expanded: {
                    ...expanded,
                    ...diff
                }
            });
        }
    };
};

export const multiFilterMethod = (filter, row) => _.get(filter.value, 'length') > 0 ? _.some(filter.value, ['value', row[filter.id]]) : true;
export const filterMethod = (filter, row) => {
    if (!filter.value) {
        return true;
    }
    return row[filter.id] === filter.value.value;
};

export const calculateSum = (filters, data) => {
    const filteredData = _.filter(data.data, (row) => _.some(filters.value, row.Account));
    let ans = {Account: 'Sum'};
    data.labels.forEach((key) => ans[key] = _.sum(_.map(filteredData, key)));
    return ans;
};



export const compareDataObjNum = (firstEL, secondEL) => {

    let result = 0;
    delete firstEL.id;
    delete secondEL.id;
    const generateValuesListAndSum = (obj) => {

        obj = Object.values(obj);
        obj = _.remove(obj, (item) => {
            return !(typeof item === 'string');
        });
        obj = obj.reduce((previosVal, currentVal) => previosVal + (typeof (currentVal) === 'number' ? currentVal : 0), 0);
        return obj;
    };

    firstEL = generateValuesListAndSum(firstEL);
    secondEL = generateValuesListAndSum(secondEL);

    if (firstEL > secondEL) result = -1;
    if (secondEL > firstEL) result = 1;
    if (firstEL === secondEL) result = 0;
    return result;
};


export const compareColumns = (rows, columns) => {

    const scoreFunc = (firstEL, secondEL) => {
        let result = 0;
        const firstColumn = firstEL.id;
        const secondColumn = secondEL.id;
        let firstColumnCounterScore = {
            sumNum: 0,
            amountOfNum: 0,
            highestNum: 0,
            score: 0
        };
        let secondColumnCounterScore = {
            sumNum: 0,
            amountOfNum: 0,
            highestNum: 0,
            score: 0
        };
        rows.map(row => {
            if (row[firstColumn] >= 1) {
                firstColumnCounterScore.sumNum += row[firstColumn];
                firstColumnCounterScore.amountOfNum += 1;
                if (row[firstColumn] > firstColumnCounterScore.highestNum) {
                    firstColumnCounterScore.highestNum = row[firstColumn];
                }
            }
            if (row[secondColumn] >= 1) {
                secondColumnCounterScore.sumNum += row[secondColumn];
                secondColumnCounterScore.amountOfNum += 1;
                if (row[secondColumn] > secondColumnCounterScore.highestNum) {
                    secondColumnCounterScore.highestNum = row[secondColumn];
                }
            }
        });


        if (firstColumnCounterScore.amountOfNum > secondColumnCounterScore.amountOfNum) {
            firstColumnCounterScore.score = 1;
            result = -1;
        } else if (firstColumnCounterScore.amountOfNum < secondColumnCounterScore.amountOfNum) {
            secondColumnCounterScore.score = 1;
            result = 1;
        } else result = 0;
        return result;
    };
    if (rows !== undefined || columns !== undefined) {
        const firstCol = columns.shift();
        columns.sort(scoreFunc);
        columns.unshift(firstCol);
        return columns;
    } else return [];


};


export const subTableCompareColumns = (rows, columns) => {

    const scoreFunc = (firstEL, secondEL) => {
        let result = 0;
        const firstColumn = firstEL.id;
        const secondColumn = secondEL.id;

        let firstColumnCounterScore = {
            sumNum: 0,
            amountOfNum: 0,
            highestNum: 0,
            score: 0
        };
        let secondColumnCounterScore = {
            sumNum: 0,
            amountOfNum: 0,
            highestNum: 0,
            score: 0
        };
        rows.map(row => {
            if (row[firstColumn] >= 1) {
                firstColumnCounterScore.sumNum += row[firstColumn];
                firstColumnCounterScore.amountOfNum += 1;
                if (row[firstColumn] > firstColumnCounterScore.highestNum) {
                    firstColumnCounterScore.highestNum = row[firstColumn];
                }
            }
            if (row[secondColumn] >= 1) {
                secondColumnCounterScore.sumNum += row[secondColumn];
                secondColumnCounterScore.amountOfNum += 1;
                if (row[secondColumn] > secondColumnCounterScore.highestNum) {
                    secondColumnCounterScore.highestNum = row[secondColumn];
                }
            }
        });
        if (firstColumnCounterScore.amountOfNum > secondColumnCounterScore.amountOfNum) {
            firstColumnCounterScore.score = 1;
            result = -1;
        } else if (firstColumnCounterScore.amountOfNum < secondColumnCounterScore.amountOfNum) {
            secondColumnCounterScore.score = 1;
            result = 1;
        } else result = 0;

        return result;
    };
    if (rows !== undefined || columns !== undefined) {
        return columns.sort(scoreFunc);
    } else return [];


};


export const subTableColumnsGenerator = (data) => {
    const findLongestRow = (data) => {
        if (Array.isArray(data)) {
            let longestRow = data[0];
            for (let i = 1; i < data.length; i++) {
                if (Object.keys(longestRow).length < Object.keys(data[i]).length) longestRow = data[i];
            }
            longestRow = Object.keys(longestRow);
            return longestRow;
        } else {
            console.log('failed to get data in findLongestRow func ');
        }
    };

    let arr = [];
    if (data !== undefined) {
        const row = findLongestRow(data);
        row.map((label, i) => {
            let col = {
                header: label,
                accessor: label,
                id: label,
                sort: {
                    enabled: true
                },
                width: 100
            };
            if (i === 0) {
                col.width = 150;
                col['cellStyle'] = () => {
                    return {fontWeight: 'bolder'};
                };
            }
            if (label.length > 9) {
                col['popoverText'] = label;
            }
            arr.push(col);
        });
    }
    return arr;
};


export const columnsGeneratorByLabels = (initCol, labels) => {
    let arr = [initCol];
    if (labels !== undefined) {
        labels.map(label => {
            if (label && label !== 'NoRegion') {
                let tempObj = {
                    header: label,
                    accessor: label,
                    id: label,
                    sort: {
                        enabled: true
                    },
                    width: 100
                };
                if (label.length > 9) {
                    tempObj['popoverText'] = label;
                }
                arr.push(tempObj);
            }
        });
    }
    return arr;
};

export const floatRowsNum = (rows) => {

    const isStringFunc = (num) => {
        return isNaN(Number(num));
    };

    let results = [];
    if (rows) {
        results = rows.map(obj => (
            Object.fromEntries(
                Object.entries(obj).map(([key, val]) => {
                        if (isStringFunc(val)) return [key, val];
                        return [key, _.isNumber(Number(val)) ? Math.round(Number(val)) : val];
                    }
                )
            )
        ));
    }
    return results;
};

export const dateEditor = (rows, label) => {

    if (rows && label) {
        const result = rows.map((row) => {
            row[label] = row[label].slice(0, -8).replace('T', ' ');
            return row;
        });
        return result;
    }
};
