import React from "react";
import styles from "./InternalTableFilterStyles.module.scss";
import {ReactComponent as FilterIcon} from "./ic-table-filter.svg";
import {PopoverMechanism} from "./../Popover";
import _ from "lodash";
import { CheckboxFilter, DropdownFilter } from "../Widgets/Filters";

const PopoverFilter = ({columnFilterOptions, columnFilterState, close, updateColumnFilter, Filter, filterConfig}) => {
    return <>
        <div className={styles["popover-arrow"]}/>
        <Filter filterOptions={columnFilterOptions} filterConfig={filterConfig} filterState={columnFilterState} close={close} updateFilter={updateColumnFilter}/>
    </>
};

const InternalTrigger = () => {
    return <FilterIcon/>
};

const internalFilters = {
    checkbox: CheckboxFilter,
    dropdown: DropdownFilter
};

const NoFilter = () => <div>Invalid Render Filter</div>;

export const chooseFilter = (filterConfig = {}) => {
    return _.isFunction(filterConfig.RenderFilter) ? filterConfig.RenderFilter : internalFilters[filterConfig.RenderFilter || "checkbox"] || NoFilter;
};

export const InternalFilter = ({columnFilterOptions, columnFilterState, updateColumnFilter, filterConfig}) => {
    const Filter = chooseFilter(filterConfig);

    return <PopoverMechanism
        Popover={PopoverFilter}
        Filter={Filter}
        Trigger={InternalTrigger}
        popoverClassName={styles['internal-filter-popover']}
        triggerClassName={styles['internal-filter-button']}
        triggerOnClick={true}
        columnFilterOptions={columnFilterOptions}
        columnFilterState={columnFilterState}
        updateColumnFilter={updateColumnFilter}
        filterConfig={filterConfig}
    />
};
