import React, { useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import MainHeader from './header/MainHeader';
import appStyles from './styles/app/app.module.scss';
import classNames from 'classnames';
import CostChartNew from './components/CostChartNew';
import CostMap from './components/CostMap';
import { useDispatch, useSelector } from 'react-redux';
import NavSlider from './navslider/NavSlider';
import { ComponentLoader, Text } from 'ui-components-rollup';
import greetingTime from 'greeting-time';
import rf from 'random-facts';
import SlidingManagementWidget from './slider/SlidingManagementWidget';
import CostTableAccountsNew from './components/CostTableAccountsNew';
import EC2InstancesTableNew from './components/EC2InstancesTableNew';
import GuardDutyTableNew from './components/GuradDutyTableNew';
import ConfigServiceTableNew from './components/ConfigServiceTableNew';
import CostTableRegionsAzureNew from './components/CostTableRegionsAzureNew';
import CostTableRegionsNew from './components/CostTableRegionsNew';
import AzureAdvisorTableNew from './components/AzureAdvisorTableNew';


const App = () => {

    const auth = useSelector(state => state.auth);
    const orgsLoaded = useSelector(state => state.organizations.orgsLoaded);
    const accountTabIsOpen = useSelector(state => state.style.accountTab);
    const navBarTabIsOpen = useSelector(state => state.style.navBarTab);
    const dispatch = useDispatch();
    let [fact, setFact] = useState(null);
    
    const handleTabsClick = useMemo(() => {
        return () => {
            if (accountTabIsOpen || navBarTabIsOpen) dispatch({ type: 'tabs:close' });
        };
    }, [accountTabIsOpen, navBarTabIsOpen]);

    useEffect(() => {
        if (!auth.authenticating && auth.authenticated===false) dispatch({ type: 'AUTH:LOGIN' });
    });

    useEffect(() => {
        if (auth.authenticated) dispatch({ type: 'ORGANIZATIONS:GETORGS' });
    }, [auth]);

    useEffect(() => {
        setFact(rf.randomFact());
    }, []);

    return <>
        {auth.authenticated && orgsLoaded ?
            <div className={classNames(appStyles.base)}>
                <SlidingManagementWidget handleTabsClick={handleTabsClick} />
                <NavSlider handleTabsClick={handleTabsClick} />
                <div onClick={() => handleTabsClick()}>
                    <MainHeader />
                    <Switch>
                        <Route exact path="/" render={() => (<Redirect to="/cost-chart" />)} />
                        <Route path="/guard-duty" component={GuardDutyTableNew} />
                        <Route path="/config-service" component={ConfigServiceTableNew} />
                        <Route path="/cost-chart" component={CostChartNew} />
                        <Route path="/cost-map" component={CostMap} />
                        <Route path="/cost-table-regions" component={CostTableRegionsNew} />
                        <Route path="/cost-table-accounts" component={CostTableAccountsNew} />
                        <Route path="/ec2-instances-table" component={EC2InstancesTableNew} />
                        <Route path="/cost-table-regions-azure" component={CostTableRegionsAzureNew} />
                        <Route path="/azure-advisor" component={AzureAdvisorTableNew} />
                    </Switch>
                </div>
            </div>

            : <div className={appStyles['load-page']}>
                <h1>{greetingTime(new Date())}!</h1>
                <h2>It will take a few second</h2>
                <ComponentLoader className={appStyles['loader-svg']} />
                <Text style={{ marginTop: '100px' }}>"{fact}"</Text>
            </div>}
    </>;
};


export default App;
