const defaultState = () => ({
    error: null,
    isTableLoaded: false,
    tableData: [],
});


export default (state = defaultState(), {payload, type}) => {
    switch (type) {
        case 'AZUREADVISOR:LOAD-DATA-SUCCESS': {
            return {...state, isTableLoaded: true, error: false, tableData: payload};
        }
        case 'AZUREADVISOR:LOAD-DATA-FAILURE': {
            return {...state, isTableLoaded: true, error: payload.response.data.message || true};
        }
        case 'ORGANIZATIONS:CHANGEORG': {
            return {...state, isTableLoaded: false};
        }
        default:
            break;
    }
    return state;
}
