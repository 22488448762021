import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import SubRow from './SubRow'
import { Heading, Text } from 'ui-components-rollup';
import Table, { TableCounter, TableWidgets, useTable } from 'ui-components-rollup/dist/Tables';
import { SearchWidget } from 'ui-components-rollup/dist/Widgets';
import { dateEditor } from '../utils/utils'
import columnStyle from './serviceStatus.module.scss';
import classNames from 'classnames';
import styles from './pageStyle.module.scss';

const GuardDutyTableNew = () => {

    const dispatch = useDispatch();
    const summeryRow = useSelector(state => state.gdTable.summeryRow);
    const tableData = useSelector(state => state.gdTable.tableData);
    const isTableLoaded = useSelector(state => state.gdTable.isTableLoaded);
    const error = useSelector(state => state.gdTable.error);
    const selectedOrg = useSelector(state => state.organizations.selectedOrg);
    let [errorText, setErrorText] = useState(null);


    const initCol = [{
        header: 'Account',
        accessor: 'Account',
        id: 'Account',
        sort: {
            enabled: false
        },
        filter: {
            enabled: true,
            RenderFilter: 'dropdown',
        },
        width: 100
    }, {
        header: 'Region',
        accessor: 'Region',
        id: 'Region',
        sort: {
            enabled: false
        },
        filter: {
            enabled: true,
            RenderFilter: 'dropdown',
        },
        width: 100
    },
    {
        header: 'Created at',
        accessor: 'CreatedAt',
        id: 'CreatedAt',
        sort: {
            enabled: true
        },
        width: 100
    },
    {
        header: 'Title',
        accessor: 'Title',
        id: 'Title',
        sort: {
            enabled: true
        },
        width: 150
    },
    {
        header: 'Type',
        accessor: 'Type',
        id: 'Type',
        sort: {
            enabled: true
        },
        width: 150
    },
    {
        header: 'Id',
        accessor: 'instanceId',
        id: 'instanceId',
        sort: {
            enabled: true
        },
        width: 100
    },
    {
        header: 'State',
        accessor: 'instanceState',
        id: 'instanceState',
        sort: {
            enabled: true
        },
        width: 100,
        Renderer: ({ value }) => {
            return <div className={columnStyle["status"]}>
                <span className={classNames(columnStyle["circle"], value === "running" ? columnStyle["regular"] : value === "stoopped" ? columnStyle["medium"] : columnStyle["noStatus"])} />
            </div>
        }
    }];


    let fixedData = useMemo(() => {
        let result = dateEditor(tableData, 'CreatedAt')
        result = result.map((row) => {
            row.Type = row.Type.replace(':', ' : ')
            return row
        })
        return result
    }, [tableData])

    const {
        sortState,
        toggleSort,
        filterState,
        updateFilterState,
        rows,
        organizedRows,
        rowState,
        updateRowState,
        updateTextFilter,
        toggleRowSelection,
        columns
    } = useTable({
        columns: initCol,
        rows: fixedData,
        isSorting: true,
        isRowSelecting: true,
    });


    const filterOptions = useMemo(() => {
        return {
            Region: _.uniqBy(rows.map((row) => {
                return { label: row.Region || 'N/A', value: row.Region };
            }), 'value'),
            Account: _.uniqBy(rows.map((row) => {
                return { label: row.Account || 'N/A', value: row.Account };
            }), 'value'),
        };
    }, [rows]);


    const onRowClick = useCallback((row) => {
        updateRowState(row.id)(rowState => ({ isExpanded: !(rowState && rowState.isExpanded) }));
    }, [updateRowState]);


    useEffect(() => {
        dispatch({ type: 'GDTABLE:LOAD-DATA' });
    }, []);

    useEffect(() => {
        if (error) setErrorText(errorText = `Failed to get table:  ${_.isString(error) ? error : ''} `);
        if (!selectedOrg) setErrorText(errorText = 'Sorry you don\'t have accounts to monitor');
    }, [error, selectedOrg]);

    return <>
        <div className={styles['rt-container']}>
            <div className={styles['header']}>
                <Heading className={styles['header-title']}>Gurad Duty</Heading>
                <p className={styles['header-line']} />
            </div>
            <div className={styles['table-widget']}>
                <TableCounter
                    filteredCount={organizedRows.length}
                    className={styles['table-counter']}
                    totalCount={rows.length}
                    pluralLabel="messages"
                    singularLabel="messages" />
                <TableWidgets>
                    <SearchWidget setFilter={updateTextFilter} />
                </TableWidgets>
            </div>
            {errorText && <Text center bold>{errorText}</Text>}
            <Table
                className={styles['table-animation']}
                isSticky headerContainerStyle={{ position: 'sticky', zIndex: 49, top: '15vh' }}
                isLoading={!isTableLoaded}
                columns={columns}
                rows={errorText ? [] : organizedRows}
                rowState={rowState}
                updateRowState={updateRowState}
                toggleRowSelection={toggleRowSelection}
                filterOptions={filterOptions}
                filterState={filterState}
                updateFilterState={updateFilterState}
                sortState={sortState}
                toggleSort={toggleSort}
                SubRowRenderer={SubRow}
                onRowClick={onRowClick}
            />

        </div>
    </>;

};

export default GuardDutyTableNew;